// * {
//   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
// }

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-style{
  width: 500px;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: white;
  display: flex;
  border-radius: 5px;
  text-align: center;
  input {
    height: 50px;
    width: 60%;
    background-color: white;
    padding-left: 10px;
    border-radius: 10px;
    align-self: center;
    margin-top: 25px;
    border: none;
    box-shadow: 0 0 10px black;
  }
  h1 {
    font-size: 40px;
    color: black;
    font-family: Righteous;
  }
  p {
    font-size: 20px;
  }
  button {
    font-size: 1rem;
    padding: 10px;
    width: 62%;
    border: none;
    background-color: #29475E;
    border-radius: 5px;
    align-self: center;
    margin-top: 30px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  a {
    margin-top: 10px;
    color: #2196f3
  }
}

.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  &_title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  &_section {
    border: solid;
    text-align: center;
    margin: 14px;
    padding: 8px;
  }

  &_container {
    display: flex;
  }

  &_inputContainer {
    position: relative;
    width: 300px;
    margin-bottom: 20px;

    &_input {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    &_inputLabel {
      position: absolute;
      top: 12px;
      left: 12px;
      font-size: 1rem;
      color: #aaa;
      pointer-events: none;
      transition: 0.2s ease all;
    }

    &_input:focus + &_inputLabel,
    &_input:valid + &_inputLabel {
      top: -20px;
      font-size: 0.8rem;
      color: #666;
    }
  }

  &_fileName {
    font-size: 1.2rem;
    margin-top: 10px;
  }
}


