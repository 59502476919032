@import "../../styles/index.scss";

.headerContainer {
	display: flex;
	justify-content: space-between;
}

.isKw {
	.isKwContainer {
		margin-left: 30px;
	}
	label {
		margin-left: 8px;
		font-size: smaller;
	}
	input {
		width: 70px;
		border: solid 1px grey;
		border-radius: 5px;
		margin-left: 5px;
	}
	&:hover {
		background-color: #ffc07a52;
	}
}

.casesTable {
	tbody tr td:not(.actionCase):hover {
		background-color: #ffc07a52;
	}
	.actionCase {
		button {
			padding: 0;
			&:last-child {
				padding: 0 0 0 12px;
			}
			&.remove {
				color: $danger-dark;
			}
			&.duplicate {
				color: $success-dark;
			}
		}
	}
}
.plusMinusButton {
	cursor: pointer;
	font-size: large;
	border: 1px solid #00000057;
	width: 20px;
	height: 20px;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: self-end;
	margin-right: 5px;
	border-radius: 3px;

	&:hover {
		border: 1px solid black;
	}
}
