@import "../../styles/index.scss";

.autocomplete {
    margin-left: 10px;
    border-radius: 10px;
    background-color: $grey;
    height: fit-content;
    align-self: center;

    .MuiInput-input {
        padding: 4px 4px 4px 8px!important;
    }
    .MuiInput-formControl {
        margin-top: 0px;
    }
    .MuiAutocomplete-endAdornment {
        display: none;
    }
    .MuiInputBase-root {
        padding-right: 0 !important;
    }
}
