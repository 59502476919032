@import "../../styles/index.scss";

.root {
    max-width: 100vw;
    display: inline-block
}

.menuContainer {
    border: 1px solid #adadad;
    display: flex;
    border-radius: 5px;
    width: fit-content;
    margin: 1rem auto;
    .itemContainer {
        cursor: pointer;
        padding: 8px 15px;
        &:not(:last-child) {
            border-right: 1px solid #adadad;
        }

        &:hover,
        &:active {
            color: #583d72;
			background-color: $primary-lightest;
			&.greenBerg{
				color: #1c4100;
				background-color: #dbfac45e;
			}
        }

        &.active {
			&.greenBerg{
				color: #1c4100;
				background-color: #dbfac4;
			}
            color: #583d72;
            background-color: $primary-lighter;
        }
    }
}
@media only screen and (max-width: $mobileWidth) {
    .menuContainer {
        flex-direction: column;
        .itemContainer {
            &:not(:last-child) {
                border-right: none;
            }
        }
    }
}

.componentContainer {
}
