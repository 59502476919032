.dropDownButton {
	border-color: #1976d2;
	border-width: 1px;
	border-style: solid;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
  
	&:hover {
	  background-color: #f5f5f5;
	}
  }
  
  .menuList {
	border: 1px solid #ccc;
	margin-top: 45px;
  }
  