:root {
	--dark-text: rgb(33, 33, 33);
	--light-text: rgb(250, 250, 250);

	--indigo-100: rgba(225, 190, 231, 0.8);
	--indigo-100-dark: rgba(225, 190, 231, 1);
	--green-500: rgba(76, 175, 80, 0.8);
	--green-500-dark: rgba(76, 175, 80, 1);
	--yellow-50: rgba(255, 249, 196, 0.8);
	--yellow-50-dark: rgba(255, 249, 196, 1);
	--amber-200: rgba(255, 224, 0.8, 0.8);
	--amber-200-dark: rgba(255, 224, 0.8, 1);
	--indigo-500: rgba(63, 81, 181, 0.8);
	--indigo-500-dark: rgba(63, 81, 181, 1);
	--orange-300: rgba(255, 183, 77, 0.8);
	--orange-300-dark: rgba(255, 183, 77, 1);
	--deep-orange-300: rgba(255, 138, 101, 0.8);
	--deep-orange-300-dark: rgba(255, 138, 101, 1);
	--lime-200: rgba(230, 238, 156, 0.8);
	--lime-200-dark: rgba(230, 238, 156, 1);
	--lime-400: rgba(212, 225, 87, 0.8);
	--lime-400-dark: rgba(212, 225, 87, 1);
	--lime-500: rgba(205, 220, 57, 0.8);
	--lime-500-dark: rgba(205, 220, 57, 1);
	--teal-300: rgba(77, 182, 172, 0.8);
	--teal-300-dark: rgba(77, 182, 172, 1);
	--green-500: rgba(76, 175, 80, 0.8);
	--green-500-dark: rgba(76, 175, 80, 1);
	--red-500: rgba(244, 67, 54, 0.8);
	--red-500-dark: rgba(244, 67, 54, 1);
	--grey-500: rgba(158, 158, 158, 0.8);
	--grey-500-dark: rgba(158, 158, 158, 1);
}

@charset "UTF-8";
/* Notification colors */
/* Rating colors */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.absolute {
	position: absolute;
}

.absolute-important {
	position: absolute !important;
}

.relative {
	position: relative;
}

.flex {
	display: flex;
}

.flex.wrap {
	flex-wrap: wrap;
}

.flex.column {
	flex-direction: column;
}

.flex.grow {
	flex-grow: 1;
}

.grow-0 {
	flex-grow: 0 !important;
}

.justify-center {
	justify-content: center;
}

.align-baseline {
	align-items: baseline !important;
}

.space-between {
	justify-content: space-between;
}

.space-around {
	justify-content: space-around;
}

.my-purple {
	color: #4e54c8 !important;
}

.bg-login {
	background: linear-gradient(90deg, white, #6e5ee2);
}

.bg-violet {
	background: #4e54c8;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(90deg, #4739a9, #6e5ee2);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(90deg, #4739a9, #6e5ee2);
}

.bg-violet-h {
	background: #4e54c8;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(180deg, #6e5ee2, #4739a9);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(180deg, #6e5ee2, #4739a9);
}

.bg-pink-h {
	background: #ed689d;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(180deg, #edb9cd, #ed689d);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(180deg, #edb9cd, #ed689d);
}

.max-weight {
	font-weight: 1000 !important;
}

.bg-green {
	background: #71bd6b !important;
}

.bg-white {
	background: white !important;
}

.bg-blue {
	background: #584ec8;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(90deg, #584ec8, #332d75);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(90deg, #584ec8, #332d75);
}

.bg-blue-h {
	background: #4e54c8;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(180deg, #332d75, #584ec8);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(180deg, #332d75, #584ec8);
}

.bg-red {
	background: #f6414e !important;
}

.color-red {
	color: #f6414e !important;
}

.color-green {
	color: #21ba45 !important;
}

.color-yellow {
	color: #d9e715 !important;
}

.bg-yellow-flash {
	background: #f4d160 !important;
}

.bg-yellow {
	background: #fae2bf !important;
}

.bg-grey {
	background: #aeaeae !important;
}

.bg-my-purple {
	background: #4e54c8 !important;
}

.bg-my-purple.transparent {
	opacity: 0.2;
}

.button.bg-my-purple {
	opacity: 0.8;
	color: white !important;
}

.button.bg-my-purple:hover {
	opacity: 1;
}

.color-white {
	color: white !important;
}

.color-black {
	color: black !important;
}

.color-blue {
	color: #001739 !important;
}

.color-pink {
	color: #ed689d;
}

.color-cyan {
	color: #50bac4;
}

.underline {
	text-decoration: underline;
	text-underline-position: under;
}

.underline-decoration-none {
	text-decoration: none !important;
}

.transparent-underline {
	border-bottom: 1px solid transparent !important;
}

.custom-underline {
	border-bottom: 1px solid !important;
}

.game-cutom-underline {
	border-bottom: solid 2px !important;
}

.underline-hover:hover {
	cursor: pointer;
	border-bottom: 1px solid !important;
}

.super-title {
	font-size: 8rem !important;
	height: 8.5rem;
}

.description-container {
	padding: 1em;
	background: rgba(78, 84, 200, 0.15);
	border-radius: 5px;
	color: black;
	font-size: small;
}

.sub-legend {
	font-size: smaller;
	margin-top: 1.5em;
}

.centered-container {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.centered-container.column {
	flex-direction: column;
}

.flex-direction-column {
	flex-direction: column;
}

.centered-item {
	align-self: center;
}

.centered-childs {
	align-items: center;
}

.margin-auto {
	margin: auto !important;
}

.margin-15 {
	margin: 15px;
}

.margin-15-i {
	margin: 10px !important;
}

.to-be-pointer {
	cursor: pointer;
	opacity: 0.7;
}

.no-pointer {
	cursor: initial !important;
}

.no-pointer:hover {
	cursor: initial !important;
}

.to-be-pointer:hover {
	opacity: 1;
}

.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 3s ease-in-out;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes dropdown {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes appears {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.background-color-1 {
	background: navajowhite;
}

.color-1 {
	color: navajowhite;
}

.location-button {
	background-color: navajowhite !important;
	color: #565656 !important;
}

.location-button:hover,
.location-button:active {
	background-color: #ffcd82 !important;
	color: rgba(0, 0, 0, 0.8) !important;
}

.localisation-icon {
	width: 100px;
	margin: 0 15px;
	animation: dropdown infinite 3s ease-in-out;
}

.localisation-icon-error {
	width: 100px;
	margin: 0 15px;
}

@media only screen and (max-width: 768px) {
	.localisation-icon-error > .web {
		display: none;
	}
	.localisation-icon-error {
		margin: 0;
	}
}

@media only screen and (min-width: 769px) {
	.localisation-icon-error > .mobile {
		display: none;
	}
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* .underline
 text-decoration: underline
 */
.font-weight {
	font-weight: 500;
}

.font-weight-700 {
	font-weight: 700;
}

.font-weight-600 {
	font-weight: 600;
}

.padding-bottom-0 {
	padding-bottom: 0;
}

.padding-b1rem {
	margin-bottom: 1rem !important;
}

.padding-top-0 {
	padding-top: 0 !important;
}

.padding-h-10 {
	padding: 0 10px;
}

.large-text {
	font-size: large;
}

.line-height-initial {
	line-height: initial;
}

.overflow-hover-x {
	overflow: auto;
}

.overflow-hover-x::-webkit-scrollbar {
	display: none;
}

.description-text-size {
	font-size: smaller;
	text-align: center;
}

@keyframes bounding {
	from {
		top: 17px;
	}
	50% {
		top: 30px;
	}
	to {
		top: 17px;
	}
}

.title-description {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	padding: 5px 31px;
	background: #3a3a3a;
	color: whitesmoke;
	z-index: 5;
	font-size: medium;
	cursor: pointer;
	animation: bounding 1.5s cubic-bezier(0.4, 0.07, 0.69, 0.96) infinite;
	transition: transform 2.5s ease-in;
}

@media only screen and (max-width: 768px) {
	.title-description {
		padding: 0px 8px;
	}
}

.mouse-over {
	cursor: pointer;
}

.round {
	border-radius: 50%;
}

.dots {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 1em;
	display: flex;
	justify-content: center;
}

.dot {
	cursor: pointer;
	margin: 0 3px;
	border-radius: 50%;
	width: 0.5em;
	height: 0.5em;
	background: #80808057;
}

.dot.active {
	background: navajowhite;
}

.z-index-behind {
	z-index: -1;
}

.z-index-standart {
	z-index: 0;
}

.z-index-third {
	z-index: 2;
}

@media only screen and (max-width: 768px) {
	.overflow-hover {
		overflow: auto;
	}
	.overflow-hover::-webkit-scrollbar {
		display: none;
	}
	.slider-size {
		width: 200px;
	}
	.privacy-container {
		max-height: 20vh;
		overflow-y: scroll;
		text-align: left;
	}
}

@media only screen and (min-width: 768px) {
	.overflow-hover {
		overflow: hidden;
	}
	.overflow-hover:hover,
	.overflow-hover:active {
		overflow: auto;
	}
	.overflow-hover::-webkit-scrollbar {
		display: none;
	}
	.slider-size {
		width: 500px;
	}
	.privacy-container {
		max-height: 30vh;
		overflow-y: scroll;
		text-align: left;
	}
}

.privacy-container-large {
	max-height: 50vh;
	overflow-y: scroll;
	text-align: left;
}

.privacy-container::-webkit-scrollbar {
	display: none;
}

.privacy-container:hover::-webkit-scrollbar {
	display: block;
}

.privacy-more-icon {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 10;
}

.text-center {
	text-align: center;
}

.text-center-important {
	text-align: center !important;
}

/* Home */
.bottom-right-side {
	background: #e5e5f6;
	opacity: 0.9;
	/* background: rgba(78, 84, 200, 0.15) */
}

.width-100 {
	width: 100%;
}

.width-100-p {
	width: 100%;
}

.grid-normal {
	height: 100%;
	margin: 0 !important;
	width: 100%;
}

.column-normal {
	height: 100%;
	padding: 0 !important;
}

.row-normal {
	padding: 0 !important;
	width: 100%;
}

.infos-container-div {
	display: flex;
	justify-content: space-evenly;
}

.ta-left {
	text-align: left;
}

.ta-right {
	text-align: right;
}

.ta-center {
	text-align: center !important;
}

.mh-75 {
	margin-top: 75px;
	margin-bottom: 75px;
}

.mh-50 {
	margin-top: 50px;
	margin-bottom: 50px;
}

.mt-5px {
	margin-top: 5px;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-75 {
	margin-top: 75px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-20 {
	margin-top: 20%;
}

.mt-23px {
	margin-top: 23px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-4 {
	margin-bottom: 4px !important;
}

.mb-2em {
	margin-bottom: 2em !important;
}

.mb-10 {
	margin-bottom: 10px;
}

/* Text formatage and space */
.mr-3 {
	margin-right: 3px;
}

.ml-2 {
	margin-left: 2em;
}

.ml-3 {
	margin-left: 3em;
}

.ml-8 {
	margin-left: 8px;
}

.ml-3x {
	margin-left: 3px;
}

.ml-5x {
	margin-left: 5px;
}

.ml-8-i {
	margin-left: 8px !important;
}

.ml-10-i {
	margin-left: 10px !important;
}

.ml-15-i {
	margin-left: 15px !important;
}

.mt-1 {
	margin-top: 1em;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-3em {
	margin-top: 3em;
}

.mt-4em {
	margin-top: 4em;
}

.mv-25 {
	margin-top: 25px;
	margin-bottom: 25px;
}

.mv-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.mv-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.mv-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.mv-5-i {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.mv-2px {
	margin-top: 2px;
	margin-bottom: 2px;
}

.mv-2 {
	margin-top: 1em;
	margin-bottom: 1em;
}

.ph-10 {
	padding: 0 10px !important;
}

h4 {
	font-weight: 700;
}

.overflow-hidden {
	overflow: hidden;
}

.font-xl {
	font-size: x-large !important;
}

.font-xl {
	font-size: xx-large !important;
}

.font-xxxl {
	font-size: xxx-large !important;
}

/* chartist */
.chartist-tooltip {
	border-radius: 5px;
	position: absolute;
	display: inline-block;
	opacity: 0;
	min-width: 5em;
	padding: 0.5em;
	background: #4e54c8;
	color: white;
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	text-align: center;
	pointer-events: none;
	z-index: 1001;
	-webkit-transition: opacity 0.2s linear;
	-moz-transition: opacity 0.2s linear;
	-o-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}

/* .chartist-tooltip:before
 content: ""
 position: absolute
 top: 100%
 left: 50%
 width: 0
 height: 0
 margin-left: -15px
 border: 15px solid transparent
 border-top-color:#4e54c8
 */
.chartist-tooltip.tooltip-show {
	opacity: 1;
}

.ct-area,
.ct-line {
	pointer-events: none;
}

.ct-label {
	fill: rgba(0, 0, 0, 0.4);
	color: rgba(0, 0, 0, 0.4);
	font-size: 0.75rem;
	line-height: 1;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
	dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
	-webkit-box-align: flex-end;
	-webkit-align-items: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: end;
}

.ct-grid {
	stroke: rgba(0, 0, 0, 0.2);
	stroke-width: 1px;
	stroke-dasharray: 2px;
}

.ct-grid-background {
	fill: none;
}

.ct-point {
	stroke-width: 10px;
	stroke-linecap: round;
}

.ct-line {
	fill: none;
	stroke-width: 4px;
}

.ct-area {
	stroke: none;
	fill-opacity: 0.1;
}

.ct-bar {
	fill: none;
	stroke-width: 10px;
}

.ct-slice-donut {
	fill: none;
	stroke-width: 60px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
	stroke: #d70206;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
	fill: #d70206;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
	stroke: #f05b4f;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
	fill: #f05b4f;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
	stroke: #f4c63d;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-area {
	fill: #f4c63d;
}

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
	stroke: #d17905;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-area {
	fill: #d17905;
}

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
	stroke: #453d3f;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-area {
	fill: #453d3f;
}

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
	stroke: #59922b;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-area {
	fill: #59922b;
}

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
	stroke: #0544d3;
}

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-area {
	fill: #0544d3;
}

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
	stroke: #6b0392;
}

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-area {
	fill: #6b0392;
}

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
	stroke: #f05b4f;
}

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-area {
	fill: #f05b4f;
}

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
	stroke: #dda458;
}

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-area {
	fill: #dda458;
}

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
	stroke: #eacf7d;
}

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-area {
	fill: #eacf7d;
}

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
	stroke: #86797d;
}

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-area {
	fill: #86797d;
}

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
	stroke: #b2c326;
}

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-area {
	fill: #b2c326;
}

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
	stroke: #6188e2;
}

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-area {
	fill: #6188e2;
}

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
	stroke: #a748ca;
}

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-area {
	fill: #a748ca;
}

.ct-square {
	display: block;
	position: relative;
	width: 100%;
}

.ct-square:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 100%;
}

.ct-square:after {
	content: '';
	display: table;
	clear: both;
}

.ct-square > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-minor-second {
	display: block;
	position: relative;
	width: 100%;
}

.ct-minor-second:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 93.75%;
}

.ct-minor-second:after {
	content: '';
	display: table;
	clear: both;
}

.ct-minor-second > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-second {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-second:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 88.8888888889%;
}

.ct-major-second:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-second > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-minor-third {
	display: block;
	position: relative;
	width: 100%;
}

.ct-minor-third:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 83.3333333333%;
}

.ct-minor-third:after {
	content: '';
	display: table;
	clear: both;
}

.ct-minor-third > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-third {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-third:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 80%;
}

.ct-major-third:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-third > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-perfect-fourth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-perfect-fourth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 75%;
}

.ct-perfect-fourth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-perfect-fourth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-perfect-fifth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-perfect-fifth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 66.6666666667%;
}

.ct-perfect-fifth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-perfect-fifth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-minor-sixth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-minor-sixth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-minor-sixth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-golden-section {
	display: block;
	position: relative;
	width: 100%;
}

.ct-golden-section:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 61.804697157%;
}

.ct-golden-section:after {
	content: '';
	display: table;
	clear: both;
}

.ct-golden-section > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-sixth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-sixth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 60%;
}

.ct-major-sixth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-sixth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-minor-seventh {
	display: block;
	position: relative;
	width: 100%;
}

.ct-minor-seventh:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
	content: '';
	display: table;
	clear: both;
}

.ct-minor-seventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-seventh {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-seventh:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 53.3333333333%;
}

.ct-major-seventh:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-seventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-octave {
	display: block;
	position: relative;
	width: 100%;
}

.ct-octave:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 50%;
}

.ct-octave:after {
	content: '';
	display: table;
	clear: both;
}

.ct-octave > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-tenth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-tenth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 40%;
}

.ct-major-tenth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-tenth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-eleventh {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-eleventh:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-eleventh > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-major-twelfth {
	display: block;
	position: relative;
	width: 100%;
}

.ct-major-twelfth:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 33.3333333333%;
}

.ct-major-twelfth:after {
	content: '';
	display: table;
	clear: both;
}

.ct-major-twelfth > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-double-octave {
	display: block;
	position: relative;
	width: 100%;
}

.ct-double-octave:before {
	display: block;
	float: left;
	content: '';
	width: 0;
	height: 0;
	padding-bottom: 25%;
}

.ct-double-octave:after {
	content: '';
	display: table;
	clear: both;
}

.ct-double-octave > svg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.ct-chart-donut .ct-label {
	fill: white;
	color: white;
	font-size: 1rem;
	line-height: 1;
	font-weight: 600;
}

.rotate-label .ct-label.ct-horizontal.ct-end {
	transform: rotate(37deg);
	margin-left: -10px;
}

.survey-card {
	border-radius: 5px;
	margin: 7.5px;
	cursor: pointer;
}

.survey-card.unavailable {
	cursor: not-allowed;
}

.blur {
	filter: blur(5px);
}

.full-height {
	height: 100vh;
	margin: 0 !important;
	overflow: auto;
}

.full-height::-webkit-scrollbar {
	display: none;
}

.padding-0 {
	padding: 0 !important;
}

.padding-10 {
	padding: 10px !important;
}

.margin-0 {
	margin: 0 !important;
}

.left-side-container {
	display: flex !important;
	flex-flow: column;
	justify-content: space-around;
}

.page-name-container {
	flex-flow: row;
	display: flex;
	width: 100%;
}

.selected-text {
	font-size: x-large;
	margin-right: 1em;
	font-weight: bold;
}

.message-linkeable {
	cursor: pointer;
	text-decoration: underline;
	text-underline-position: under;
}

.pointer {
	cursor: pointer;
}

.text-color {
	color: black;
}

.fill-color {
	fill: #aeaeae;
}

.text-active {
	color: black;
}

.container-icon-description {
	display: flex;
}

.text-icon-login {
	margin-left: 1em;
	display: inline-block;
	vertical-align: top;
	font-size: medium;
}

.margin-bottom-login-icon {
	margin-bottom: 1em;
}

.navbar-button-logout {
	width: 147px;
}

/*Right Side login */
.top-icon {
	margin-right: 5em;
	margin-top: 2em;
}

.bottom-icon {
	margin-left: 5em;
	margin-bottom: 2em;
}

.right-side-text-container {
	display: flex !important;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	margin-left: 45%;
	margin-top: 25px;
	text-align: left;
}

.right-side-text-container > div {
	margin-left: -45%;
}

.right-side-text-container > div .link {
	margin-left: 4px;
}

.right-side-text-container > div .right-side-login-link,
.right-side-text-container > div .link {
	font-size: smaller;
	color: rgba(255, 255, 255, 0.85);
	font-weight: bold;
}

.right-side-text-container > div .right-side-login-link .link,
.right-side-text-container > div .right-side-login-link.link:active,
.right-side-text-container > div .right-side-login-link.link:hover,
.right-side-text-container > div .link .link,
.right-side-text-container > div .link.link:active,
.right-side-text-container > div .link.link:hover {
	text-decoration: underline;
	outline: none;
	color: white;
}

.right-side-login-title {
	font-size: 1.8rem;
	color: white;
	font-weight: 1000;
}

@keyframes white-intensity {
	from {
		color: rgba(255, 255, 255, 0.75);
	}
	50% {
		color: white;
	}
	to {
		color: rgba(255, 255, 255, 0.75);
	}
}

.right-side-login-text {
	font-size: 0.85rem;
	color: rgba(255, 255, 255, 0.75);
	max-width: 460px;
	line-height: 1.6;
	animation: white-intensity 5s ease-in infinite;
}

/* Form */
.ui.pointing.label:before {
	z-index: 0;
}

.hidden {
	display: none;
}

.question-form-container {
	display: flex;
	align-items: center;
}

.form-box {
	border: 2px solid blue;
	margin: 10px;
}

.question-form-container .info {
	min-width: 15vw;
	margin-left: 35px;
	position: absolute;
	z-index: 10;
	background: #e5e5f6;
	padding: 8px;
	border-radius: 3px;
	font-weight: 500;
	color: black;
	font-size: small;
	cursor: help;
}

.question-form-container .info.top {
	bottom: 140%;
	left: -250%;
}

.question-form-container .info.top.max-width {
	max-width: 165px;
}

.question-form-container:hover .info {
	display: block;
}

.question-form-container .icon {
	cursor: help;
	margin: 0 5px;
	color: #4e54c8;
}

.question-form-container .icon:hover {
	opacity: 0.8;
}

.title-form {
	color: black;
	font-weight: 500;
	font-size: medium;
}

.legend-input {
	font-size: smaller;
	margin-left: 5px;
	text-decoration: underline;
	text-underline-position: under;
	cursor: default;
}

.max-height-form-fields {
	overflow-y: auto;
	max-height: 65vh;
}

.max-height-form-fields::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #f5f5f5;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.max-height-form-fields::-webkit-scrollbar {
	width: 12px;
	background-color: #f5f5f5;
}

.max-height-form-fields::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #4e54c8;
	-webkit-box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3);
	box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3);
}

.overflow-y {
	overflow-y: auto;
}

.empty-fields-responsive {
	display: flex;
	overflow-x: auto;
	max-height: 3em;
}

/* LogGrid */
.white {
	color: white;
}

.max-height {
	height: 100%;
}

.left-container-home {
	margin: 0 !important;
	padding: 2em 0 !important;
	text-align: left;
}

.mb-item {
	margin-bottom: 1.4em;
}

.separator-items {
	width: 10px;
	height: 1px;
	background: white;
}

.item-menu {
	opacity: 0.7;
}

.item-menu.active {
	opacity: 1;
	font-weight: bold;
}

.item-menu:hover {
	opacity: 1;
	font-weight: bold;
}

.icon-item {
	vertical-align: text-top;
}

.icon-item.right {
	margin-left: 5px;
}

.icon-item.left {
	margin-right: 5px;
}

.last-item {
	position: absolute;
	bottom: 0;
	margin-bottom: 0;
}

/* Grid */
.title-row {
	height: 10%;
}

.steps-title {
	position: absolute;
	right: 0;
	align-self: flex-end;
	margin: 0 3em 0 0 !important;
}

.half-row {
	min-height: 45%;
	margin-bottom: 10px;
}

.eighty-row {
	height: 80%;
}

.form {
	text-align: left;
}

.form-container {
	display: flex;
}

.description-input {
	max-width: 550px;
}

.conform-label {
	display: inline-block;
	width: auto;
	margin-top: 0;
	margin-bottom: 0;
	vertical-align: baseline;
	font-size: 0.92857143em;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.87);
	text-transform: none;
}

/* .wrap-form > .field
 display: -webkit-inline-box
 */
.vertical-width-90 {
	margin-left: auto;
	margin-right: auto;
	width: 90% !important;
	max-height: 100%;
}

.status-icon {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-left: 5px;
}

/* Offers */
.description-offer {
	padding: 0 14%;
	margin: 2em 0;
	font-size: 0.8rem;
}

.vertical-center {
	margin-left: auto;
	margin-right: auto;
}

.horizontal-center {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.signet-offer {
	position: absolute;
	top: -9px;
	left: -1px;
	background: #21ba45;
	font-size: smaller;
	padding: 1px 10px;
	color: white;
	border-radius: 3px;
}

.bandeau-offer {
	width: 110%;
	position: absolute;
	padding: 2px 10px;
	left: -5%;
	background: blueviolet;
	color: white;
	font-size: smaller;
	border-radius: 3px;
	bottom: 10px;
	max-height: 42px;
	line-height: normal;
	font-weight: 600;
}

.legend-offer {
	padding: 0px 10%;
	font-size: smaller;
	margin-top: 1em;
	line-height: normal;
}

.segments-container {
	width: 65%;
	max-width: 284px;
	text-align: left;
}

.legend-label-price {
	font-size: 0.6rem;
	line-height: 1;
	display: flex;
	flex-flow: column-reverse;
	padding-bottom: 2px;
}

.legend-price {
	position: absolute;
	font-size: 0.8rem;
	font-style: italic;
	text-underline-position: under;
	text-decoration: underline;
}

/* header */
.header-container {
	position: fixed;
	right: 0;
	z-index: 10;
	top: 0;
	margin: 1em;
}

.header-icon-container {
	display: flex;
	flex-flow: row-reverse;
}

.header-icon {
	cursor: pointer;
	color: black;
	opacity: 0.6 !important;
}

.bell-header-icon {
	cursor: pointer;
	color: #4e54c8;
}

.header-icon:hover {
	color: #4e54c8;
}

.notification-bell-number {
	position: absolute;
	top: -3px;
	right: 2px;
	font-size: smaller;
	color: #4e54c8;
}

.dialog-header-container {
	width: 15vw !important;
}

/* position: fixed */
.close-dialog {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.5);
}

.close-dialog:hover {
	color: black !important;
}

.password-meter {
	font-weight: bold;
	height: 7px;
	border-radius: 4px;
	margin-top: 7px;
	margin-left: 5px;
	margin-right: 5px;
	width: 87%;
	background-color: lightgray;
}

.strength-password-name {
	font-weight: bold;
}

.password-progress-bar-25 {
	width: 25%;
	height: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: darkred;
}

.password-progress-bar-50 {
	width: 50%;
	height: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: orange;
}

.password-progress-bar-75 {
	width: 75%;
	height: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: yellow;
}

.password-progress-bar-75 {
	width: 75%;
	height: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: yellow;
}

.password-progress-bar-100 {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color: greenyellow;
}

/* Survey Button */
.survey-button-container {
	background: rgba(78, 84, 200, 0.1);
	border: dashed 1px;
	border-radius: 5px;
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
}

.survey-button-container:hover {
	background: rgba(78, 84, 200, 0.2);
	cursor: pointer;
}

.survey-button-container .header {
	margin-left: 15px;
}

.survey-button-container .plus {
	position: absolute;
	right: 3px;
	bottom: 10px;
}

.my-divider {
	height: 1px;
	width: 100%;
}

.vertical-divider {
	width: 1px;
	height: 2em;
	margin: auto;
}

.filter-container {
	display: flex;
	justify-content: center;
	margin-bottom: 2em;
}

.filter-age-range-container {
	width: -webkit-fill-available;
	justify-content: space-around;
	display: flex;
	flex-direction: row;
}

.filter-age-range-container .filter-age-range {
	width: -webkit-fill-available;
	margin: 2px !important;
	padding: 5px 5px 0 5px;
	border-radius: 5px;
	border: 0.5px solid rgba(78, 84, 200, 0.2);
	font-size: x-large;
	font-weight: 500;
	cursor: pointer;
}

.filter-age-range-container .filter-age-range:hover,
.filter-age-range-container .filter-age-range.active:hover {
	background: rgba(78, 84, 200, 0.61);
	box-shadow: 0 2px 8px #bbb;
}

.filter-age-range-container .filter-age-range.active {
	background: rgba(78, 84, 200, 0.2);
	box-shadow: 0 2px 8px #bbb;
}

.filter-age-range-container .filter-age-range .filter-age-range-percent {
	font-size: 0.6rem !important;
	width: fit-content;
	float: right;
	position: relative;
	bottom: 0;
}

.filter-title {
	margin-right: 10px !important;
}

.filter-label {
	margin-right: 5px !important;
}

.filter-element-container {
	padding: 0 10px;
	border-left: solid 1px #4e54c8;
}

.filter-container .header {
	margin-bottom: 0;
}

.no-gender-container .header {
	margin: 0 20px !important;
}

.container-div {
	flex-direction: row;
	display: flex;
	margin: auto;
	word-break: break-all;
}

.guide-text {
	font-weight: 500;
	font-size: 1.6em;
	margin: auto;
}

.button-guide {
	margin-left: 1em !important;
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.container-guide {
	overflow: hidden;
}

.icon-guide {
	position: absolute;
	left: 6px;
	bottom: 11.5px;
	z-index: 1;
	color: #4e54c8 !important;
}

.guide-popup {
	color: black;
}

.slide-active {
	animation: slide 1.5s forwards;
}

.slide-inactive {
	animation: slide-out 1.5s forwards;
}

#slide {
	position: absolute;
	left: 0;
}

@keyframes slide {
	from {
		width: 0%;
		opacity: 0;
		height: 51px;
	}
	0% {
		height: 51px;
	}
	to {
		opacity: 1;
		height: 51px;
		width: 100%;
	}
}

@keyframes slide-out {
	from {
		width: 100%;
		opacity: 1;
		height: 51px;
	}
	99% {
		height: 51px;
		opacity: 0;
	}
	100% {
		height: 0;
		opacity: 1;
	}
	to {
		width: 0%;
	}
}

.container-reservation {
	height: 51px;
	position: absolute;
	right: 0;
	opacity: 1;
	width: 100%;
	bottom: 0px;
}

.reservation-active {
	animation-fill-mode: backwards;
	animation-duration: 3s;
	animation-name: slide-in-right-to-left;
}

.reservation-inactive {
	animation-fill-mode: forwards;
	animation-name: slide-out-left-to-right;
	animation-duration: 3s;
}

.reservation-top {
	bottom: 0px;
}

.reservation-bottom {
	bottom: 0;
}

.icon-reservation {
	position: absolute;
	bottom: 11.5px;
	right: 18px;
	z-index: 1;
	color: #4e54c8 !important;
}

.reservation-popup {
	color: black;
}

@keyframes slide-out-left-to-right {
	from {
		width: 100%;
	}
	93% {
		width: 4%;
	}
	100% {
		width: 0;
		bottom: 0px;
	}
	to {
		background-color: transparent;
	}
}

@keyframes slide-in-right-to-left {
	0% {
		width: 4%;
	}
	7% {
		width: 4%;
	}
	100% {
		width: 100%;
	}
}

.height-50 {
	height: 55%;
}

.height-5px {
	height: 5px !important;
}

.article-card-container {
	width: 200px;
	height: 300px;
	position: relative;
	border-radius: 15px;
	box-shadow: 0 2px 8px #bbb;
	display: flex;
	flex-direction: column;
	background: white;
}

.article-card-container img {
	height: 80%;
	object-fit: cover;
	object-position: top;
	overflow: hidden;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.article-card-container img.half {
	height: 45%;
}

.article-card-container .percentage-text {
	font-size: 1.71429rem;
	font-weight: 800;
	padding: 0 10px;
}

.article-card-container .percentage-tags {
	padding: 0 10px;
	font-style: italic;
	font-weight: 500;
	color: #5d5d5d;
}

.article-card-container .hashtags {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	background: #37249b;
	color: white;
	font-size: 10px;
	font-weight: 500;
}

.article-card-container .hashtags > {
	margin-left: 5px;
}

.article-card-container .name {
	flex-grow: 1;
	font-size: large;
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	color: black;
}

.article-card-container .button-hovered {
	position: absolute;
	background: rgba(198, 198, 198, 0.7);
	width: 100%;
	height: 100%;
	border-radius: 15px;
	opacity: 0;
}

.article-card-container .hover-stuff {
	color: black;
	font-size: 13px;
	font-weight: 600px;
	position: relative;
	transform: translateY(-50%);
	top: 50%;
}

.article-card-container.minus {
	width: 66.66667px;
	height: 100px;
}

.article-card-container.minus .hashtags {
	font-size: 5px;
}

.article-card-container.minus .hashtags > {
	margin-left: 2.5px;
}

.article-card-container.minus .percentage-text {
	font-size: 0.85714rem;
}

.article-card-container.minus .percentage-tags {
	font-size: 5px;
}

.article-card-container.minus .name {
	font-size: xx-small;
}

.article-card-container.smaller {
	width: 100px;
	height: 150px;
}

.article-card-container.smaller .hashtags {
	font-size: 6.66667px;
}

.article-card-container.smaller .hashtags > {
	margin-left: 3.33333px;
}

.article-card-container.smaller .percentage-text {
	font-size: 1.14286rem;
}

.article-card-container.smaller .percentage-tags {
	font-size: 6.66667px;
}

.article-card-container.smaller .name {
	font-size: x-small;
}

.article-card-container.small {
	width: 100px;
	height: 171.42857px;
}

.article-card-container.small .hashtags {
	font-size: 8px;
}

.article-card-container.small .hashtags > {
	margin-left: 4px;
}

.article-card-container.small .percentage-text {
	font-size: 1.37143rem;
}

.article-card-container.small .percentage-tags {
	font-size: 8px;
}

.article-card-container.small .name {
	font-size: x-small;
}

.article-card-container.big {
	width: 300px;
	height: 450px;
}

.article-card-container.big .hashtags {
	font-size: 15px;
}

.article-card-container.big .hashtags > {
	margin-left: 2.5px;
}

.article-card-container.big .name {
	font-size: x-large;
}

.article-card-container:hover {
	cursor: pointer;
	box-shadow: 0 4px 16px #bbb;
}

.article-card-container:hover .button-hovered {
	opacity: 1;
}

.article-card-container .minus-icon {
	font-size: 0.75rem;
}

.article-card-container .smaller-icon {
	font-size: 1rem;
}

.article-card-container .small-icon {
	font-size: 1.2rem;
}

.article-card-container .regular-icon {
	font-size: 1.5rem;
}

.iphone-x .article-card-container {
	margin: 15px auto;
}

.iphone-x .swipeable-buttons-container {
	justify-content: space-evenly;
}

.image-height-card {
	object-fit: cover;
}

@media only screen and (max-width: 766px) {
	.carousel-container {
		max-height: 165px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 200px;
	}
	.image-height {
		height: 165px;
		margin: auto 10px !important;
	}
	.icon-carousel-container {
		height: 165px;
	}
	.max-height-none {
		max-height: 400px !important;
		max-width: 400px !important;
	}
	.image-height-max {
		max-height: 300px !important;
		max-width: 300px !important;
	}
	.image-height-card {
		width: 165px;
		height: 165px;
	}
}

@media only screen and (min-width: 767px) {
	.image-height-max {
		max-height: 400px !important;
		max-width: 400px !important;
	}
	.max-height-none {
		max-height: none !important;
		max-width: none !important;
	}
	.carousel-container {
		max-height: 250px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 400px;
	}
	.image-height {
		height: 250px;
		margin: auto 10px !important;
	}
	.icon-carousel-container {
		height: 250px;
	}
	.carousel-container-container-no-padding-mobile {
		padding-top: 50px;
	}
	.image-height-card {
		width: 250px;
		height: 250px;
	}
}

.carousel-container-container {
	padding-top: 50px;
}

.carousel-container {
	border-top: solid 1px #fffafe;
}

.icon-carousel-container {
	background: #fffafe;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.icon-carousel-container:hover .mouse-over {
	color: black;
}

.icon-carousel-container.right {
	border-radius: 0 5px 5px 0;
}

.icon-carousel-container.left {
	border-radius: 5px 0 0 5px;
}

.icon-carousel-container:hover {
	background: #fde7f9;
}

.modal-image-container {
	height: 80vh;
}

.keywords-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.keywords-container .label {
	margin-right: 10px;
	color: #ffffff;
	cursor: pointer;
	color: #565656;
	background-color: rgba(78, 84, 200, 0.1);
	-webkit-transition: background-color 1s;
	transition: background-color 1s;
}

.keywords-container .label.focus {
	color: #ffffff;
	background-color: #cb8cff;
}

.keywords-container .label:hover {
	color: #ffffff;
	background-color: #cb8cff;
}

.keywords-container.materials .label.focus:first-child {
	background-color: #ffaaaa;
}

.keywords-container.materials .label:first-child:hover {
	background-color: #ffaaaa;
}

.keywords-container.materials .label.focus:nth-child(2) {
	background-color: #fdf3be;
	color: #565656;
}

.keywords-container.materials .label:nth-child(2):hover {
	background-color: #fdf3be;
	color: #565656;
}

.keywords-container.materials .label.focus:nth-child(3) {
	background-color: thistle;
}

.keywords-container.materials .label:nth-child(3):hover {
	background-color: thistle;
}

.h-fit {
	height: fit-content;
}

.preview-image {
	max-height: 50px;
}

/* .ui.form .inline.field > :first-child {
	margin: 0;
} */

/* .auth-layout-container {
	display: grid;
	grid-template-columns: 20% 80%;
	height: 100vh;
} */

.log-grid-menu-container {
	position: fixed !important;
	height: 100vh;
	width: 19vw !important;
	/* margin-left: 19vw */
}

.log-grid-right-side-container {
	width: 81vw !important;
	margin-left: 19vw;
}

.padding-3-vh {
	padding: 3vh 2px;
}

.padding-1-5-vh {
	padding: 1.5vh 2px;
}

.guide-container {
	position: fixed;
	bottom: 0;
	width: 81vw !important;
	/* height: 10vh */
}

.ui.grid > .row.guide-container {
	width: 81vw !important;
}

.right-side-container {
	width: 100%;
}

.right-side-container.guide {
	margin-bottom: 20vh;
}

/* title */
.title-container {
	z-index: 9;
	top: 0;
	padding-top: 10px;
	width: 81vw;
	/* position: fixed --> make system to put fixed on move (when div top !== 0 || slide to down) */
}

.title-title {
	display: inline-block;
}

.title-container .status-container {
	margin-left: 15px;
}

.title-container .icon-status-container {
	padding-top: 4px;
}

.title-container .status-container .header {
	margin-top: 0;
	margin-bottom: 10px;
}

.title-container .responses-status {
	padding: 8px;
	border-radius: 9px;
	font-weight: 600;
	margin-left: 15px;
	color: black;
	margin-bottom: auto;
}

/* page */
.page-container {
	padding-top: 3em;
	width: 100%;
	position: relative;
}

/* profile */
.profile-action-container {
	margin-top: 75px;
}

.profile-infos-container {
	margin-left: 20%;
	margin-top: 80px;
}

/* support */
.support-subtitle-container {
	margin-top: 2em;
}

.subtitle {
	font-size: 1rem;
	margin-bottom: 5px;
}

.support.cards-container {
	margin-top: 5em;
	display: flex;
}

.card-container.not-first {
	margin-left: 6em;
}

.support .card-content {
	padding: 1em 2em;
	border-radius: 5px;
	display: inline-block;
}

.card-content .title-infos {
	margin-bottom: 40px;
}

.card-content .sub-infos {
	margin: 0 0 10px 0;
	font-weight: 500;
}

.italic {
	font-style: italic;
}

/* si bug verifier ici */
.ui.grid > * {
	padding-left: 0;
	padding-right: 0;
}

.list-container {
	width: 100%;
	height: 100%;
	position: absolute;
}

.list-div {
	margin: auto;
	width: 90%;
	max-height: 45%;
	overflow-y: auto;
}

.list-div.list-brand {
	max-height: 100%;
}

.list-div::-webkit-scrollbar-track {
	background-color: #f5f5f5;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list-div::-webkit-scrollbar {
	width: 12px;
	background-color: #f5f5f5;
}

.list-div::-webkit-scrollbar-thumb {
	border-radius: 1px;
	background-color: #4e54c8;
	-webkit-box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3);
	box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3);
}

.list-row {
	position: relative;
	z-index: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.list-row:hover {
	background: rgba(78, 84, 200, 0.15);
}

.list-row-column {
	position: relative;
	z-index: 1;
	margin: 1em;
	align-self: center;
}

.list-row-column.header {
	margin: 0.2em;
}

.button-clickeable {
	position: relative;
	z-index: 2;
}

.button-clickeable.update {
	background: #fae2bf !important;
	color: black;
}

.button-clickeable.update:hover {
	opacity: 0.8;
	background: #fae2bf !important;
	color: black;
}

/* .ui.table tr td
	  border-top: 1px solid #4e54c8 !important
	 */
.border-none {
	border: none;
}

/* .ui.table
	border-bottom: 1px solid #4e54c8 !important
   */
.row-table {
	border-left: 1px solid #4e54c8 !important;
	position: relative;
	z-index: 1;
	padding: 0.4em;
}

.row-table h4 {
	font-size: 0.9rem;
}

.left-background-list-brand {
	position: absolute;
	width: 30%;
	background: rgba(78, 84, 200, 0.15);
	height: 120%;
	top: -10%;
	z-index: -1;
	border-radius: 10px;
}

.table-hm {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.brand-users-list {
	height: 200px;
	margin-bottom: 10px;
	overflow-y: auto;
	border: 1px dashed #4e54c8;
	padding: 1em 0 !important;
}

.brand-users-list::-webkit-scrollbar {
	display: none;
}

.brand-users-list .user {
	border: 1px solid #4e54c8;
	border-left: 0;
	border-right: 0;
	padding: 5px;
}

.brand-users-list .user.no-top-border {
	border-top: 0;
}

.brand-users-list .user:hover {
	background: rgba(78, 84, 200, 0.2);
}

.brand-users-list .user .close-user {
	cursor: pointer;
	float: right;
}

.back-link {
	position: absolute;
	left: 23px;
	font-weight: 1000;
	cursor: pointer;
	opacity: 0.8;
	display: flex;
	margin: 0 !important;
}

.back-link.unavailable {
	cursor: not-allowed;
}

.back-link label {
	cursor: pointer;
}

.back-link:not(.unavailable):hover {
	opacity: 1;
}

.icon-back-link {
	margin: 0 !important;
}

.icon-back-link:hover {
	opacity: 1;
}

.step-text {
	position: absolute;
	color: #4e54c8;
	right: 90px;
	font-weight: 600;
	margin-top: 2px;
}

@media screen and (max-width: 1600px) {
	.text-back-link {
		display: none;
	}
}

.next-step-button {
	position: absolute !important;
	right: 5%;
	bottom: 5%;
}

.purple-background-button {
	background-color: #4e54c8 !important;
	opacity: 0.8;
}

.purple-background-button:hover {
	opacity: 1;
}

.sub-subtitle {
	width: 65%;
}

.log-grid-container .log-grid-menu-container {
	transition: 1s width;
}

.log-grid-container .menu-hide-icon-container {
	z-index: 10;
	position: fixed;
	left: 16vw;
	top: 5px;
	cursor: pointer;
	transition: left 1s, top 1s;
}

.log-grid-container
	.menu-hide-icon-container
	.ui.toggle.checkbox
	input:checked
	~ .box:before,
.log-grid-container
	.menu-hide-icon-container
	.ui.toggle.checkbox
	input:checked
	~ label:before {
	background-color: #a979ff !important;
}

.log-grid-container .left-container-home .menu-items div {
	opacity: 1;
	transition-property: opacity;
	transition-delay: 1s;
}

.log-grid-container.hide-menu .menu-hide-icon-container {
	left: 0vw;
	top: 2vh;
	transition: left 1s, top 1s;
}

.log-grid-container.hide-menu .log-grid-right-side-container {
	width: 98vw !important;
	margin-left: 2vw;
}

.log-grid-container.hide-menu .log-grid-right-side-container .title-container,
.log-grid-container.hide-menu .log-grid-right-side-container .guide-container {
	width: 98vw !important;
}

.log-grid-container.hide-menu .log-grid-menu-container {
	width: 2vw !important;
	transition: 1s width;
}

.log-grid-container.hide-menu
	.log-grid-menu-container
	.left-container-home
	.menu-items
	div {
	opacity: 0;
	transition: opacity 0s;
}

.width-1 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 1% !important;
	max-height: 100%;
}

.width-2 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 2% !important;
	max-height: 100%;
}

.width-3 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 3% !important;
	max-height: 100%;
}

.width-4 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 4% !important;
	max-height: 100%;
}

.width-5 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 5% !important;
	max-height: 100%;
}

.width-6 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 6% !important;
	max-height: 100%;
}

.width-7 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 7% !important;
	max-height: 100%;
}

.width-8 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 8% !important;
	max-height: 100%;
}

.width-9 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 9% !important;
	max-height: 100%;
}

.width-10 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 10% !important;
	max-height: 100%;
}

.width-11 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 11% !important;
	max-height: 100%;
}

.width-12 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 12% !important;
	max-height: 100%;
}

.width-13 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 13% !important;
	max-height: 100%;
}

.width-14 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 14% !important;
	max-height: 100%;
}

.width-15 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 15% !important;
	max-height: 100%;
}

.width-16 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 16% !important;
	max-height: 100%;
}

.width-17 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 17% !important;
	max-height: 100%;
}

.width-18 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 18% !important;
	max-height: 100%;
}

.width-19 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 19% !important;
	max-height: 100%;
}

.width-20 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 20% !important;
	max-height: 100%;
}

.width-21 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 21% !important;
	max-height: 100%;
}

.width-22 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 22% !important;
	max-height: 100%;
}

.width-23 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 23% !important;
	max-height: 100%;
}

.width-24 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 24% !important;
	max-height: 100%;
}

.width-25 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 25% !important;
	max-height: 100%;
}

.width-26 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 26% !important;
	max-height: 100%;
}

.width-27 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 27% !important;
	max-height: 100%;
}

.width-28 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 28% !important;
	max-height: 100%;
}

.width-29 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 29% !important;
	max-height: 100%;
}

.width-30 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 30% !important;
	max-height: 100%;
}

.width-31 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 31% !important;
	max-height: 100%;
}

.width-32 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 32% !important;
	max-height: 100%;
}

.width-33 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 33% !important;
	max-height: 100%;
}

.width-34 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 34% !important;
	max-height: 100%;
}

.width-35 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 35% !important;
	max-height: 100%;
}

.width-36 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 36% !important;
	max-height: 100%;
}

.width-37 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 37% !important;
	max-height: 100%;
}

.width-38 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 38% !important;
	max-height: 100%;
}

.width-39 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 39% !important;
	max-height: 100%;
}

.width-40 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 40% !important;
	max-height: 100%;
}

.width-41 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 41% !important;
	max-height: 100%;
}

.width-42 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 42% !important;
	max-height: 100%;
}

.width-43 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 43% !important;
	max-height: 100%;
}

.width-44 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 44% !important;
	max-height: 100%;
}

.width-45 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 45% !important;
	max-height: 100%;
}

.width-46 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 46% !important;
	max-height: 100%;
}

.width-47 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 47% !important;
	max-height: 100%;
}

.width-48 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 48% !important;
	max-height: 100%;
}

.width-49 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 49% !important;
	max-height: 100%;
}

.width-50 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 50% !important;
	max-height: 100%;
}

.width-51 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 51% !important;
	max-height: 100%;
}

.width-52 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 52% !important;
	max-height: 100%;
}

.width-53 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 53% !important;
	max-height: 100%;
}

.width-54 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 54% !important;
	max-height: 100%;
}

.width-55 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 55% !important;
	max-height: 100%;
}

.width-56 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 56% !important;
	max-height: 100%;
}

.width-57 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 57% !important;
	max-height: 100%;
}

.width-58 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 58% !important;
	max-height: 100%;
}

.width-59 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 59% !important;
	max-height: 100%;
}

.width-60 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 60% !important;
	max-height: 100%;
}

.width-61 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 61% !important;
	max-height: 100%;
}

.width-62 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 62% !important;
	max-height: 100%;
}

.width-63 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 63% !important;
	max-height: 100%;
}

.width-64 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 64% !important;
	max-height: 100%;
}

.width-65 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 65% !important;
	max-height: 100%;
}

.width-66 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 66% !important;
	max-height: 100%;
}

.width-67 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 67% !important;
	max-height: 100%;
}

.width-68 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 68% !important;
	max-height: 100%;
}

.width-69 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 69% !important;
	max-height: 100%;
}

.width-70 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 70% !important;
	max-height: 100%;
}

.width-71 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 71% !important;
	max-height: 100%;
}

.width-72 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 72% !important;
	max-height: 100%;
}

.width-73 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 73% !important;
	max-height: 100%;
}

.width-74 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 74% !important;
	max-height: 100%;
}

.width-75 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 75% !important;
	max-height: 100%;
}

.width-76 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 76% !important;
	max-height: 100%;
}

.width-77 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 77% !important;
	max-height: 100%;
}

.width-78 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 78% !important;
	max-height: 100%;
}

.width-79 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 79% !important;
	max-height: 100%;
}

.width-80 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 80% !important;
	max-height: 100%;
}

.width-81 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 81% !important;
	max-height: 100%;
}

.width-82 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 82% !important;
	max-height: 100%;
}

.width-83 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 83% !important;
	max-height: 100%;
}

.width-84 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 84% !important;
	max-height: 100%;
}

.width-85 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 85% !important;
	max-height: 100%;
}

.width-86 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 86% !important;
	max-height: 100%;
}

.width-87 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 87% !important;
	max-height: 100%;
}

.width-88 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 88% !important;
	max-height: 100%;
}

.width-89 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 89% !important;
	max-height: 100%;
}

.width-90 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 90% !important;
	max-height: 100%;
}

.width-91 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 91% !important;
	max-height: 100%;
}

.width-92 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 92% !important;
	max-height: 100%;
}

.width-93 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 93% !important;
	max-height: 100%;
}

.width-94 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 94% !important;
	max-height: 100%;
}

.width-95 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 95% !important;
	max-height: 100%;
}

.width-96 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 96% !important;
	max-height: 100%;
}

.width-97 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 97% !important;
	max-height: 100%;
}

.width-98 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 98% !important;
	max-height: 100%;
}

.width-99 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 99% !important;
	max-height: 100%;
}

.width-100 {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 100% !important;
	max-height: 100%;
}

@media only screen and (max-width: 950px) {
	.width-1 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-2 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-3 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-4 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-5 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-6 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-7 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-8 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-9 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-10 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-11 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-12 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-13 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-14 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-15 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-16 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-17 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-18 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-19 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-20 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-21 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-22 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-23 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-24 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-25 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-26 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-27 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-28 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-29 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-30 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-31 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-32 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-33 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-34 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-35 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-36 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-37 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-38 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-39 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-40 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-41 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-42 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-43 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-44 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-45 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-46 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-47 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-48 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-49 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-50 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-51 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-52 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-53 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-54 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-55 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-56 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-57 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-58 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-59 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-60 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-61 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-62 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-63 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-64 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-65 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-66 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-67 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-68 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-69 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-70 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-71 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-72 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-73 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-74 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-75 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-76 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-77 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-78 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-79 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-80 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-81 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-82 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-83 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-84 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-85 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-86 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-87 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-88 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-89 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-90 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-91 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-92 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-93 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-94 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-95 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-96 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-97 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-98 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-99 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
	.width-100 {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
		max-height: 100%;
	}
}

.upgrade-container {
	display: inline-block;
	position: relative;
}

.upgrade-container button {
	white-space: nowrap;
}

.upgrade-container svg {
	position: absolute;
	right: -29px;
	top: -4px;
}

.iphone-x {
	position: relative;
	margin: 40px auto;
	width: 240px;
	height: 480px;
	border-radius: 30px;
	box-shadow: 0 4px 16px;
}

.iphone-x:before,
.iphone-x:after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.iphone-x:after {
	bottom: 7px;
	width: 140px;
	height: 4px;
	background-color: #f2f2f2;
	border-radius: 10px;
}

.iphone-x:before {
	top: 0px;
	width: 56%;
	height: 30px;
	border-radius: 0px 0px 40px 40px;
}

.iphone-x i,
.iphone-x b {
	position: absolute;
	display: block;
	color: transparent;
}

.iphone-x .top-container {
	position: relative;
	left: 50%;
	top: 0;
}

.iphone-x .top-container > * {
	background-color: #e6e6e6;
	box-shadow: inset 0px -3px 3px 0px rgba(255, 255, 255, 0.2);
}

.iphone-x .top-container i {
	height: 6px;
	width: 15%;
	transform: translate(-50%, 6px);
	border-radius: 8px;
}

.iphone-x .top-container b {
	left: 10%;
	top: 4.5px;
	width: 8px;
	height: 8px;
	border-radius: 8px;
}

.iphone-x .content {
	margin-top: 30px;
}

@keyframes spinRight {
	0% {
		opacity: 1;
		transform: translate(0px) rotate(15deg);
	}
	50% {
		opacity: 1;
		transform: translate(10px) rotate(30deg);
	}
	100% {
		opacity: 0;
		transform: translate(15px, -10%) rotate(35deg);
	}
}

@keyframes spinLeft {
	0% {
		opacity: 1;
		transform: translate(0px) rotate(-15deg);
	}
	50% {
		opacity: 1;
		transform: translate(-10px) rotate(-30deg);
	}
	100% {
		opacity: 0;
		transform: translate(-15px, 10%) rotate(-35deg);
	}
}

.swipeable-buttons-container {
	display: flex;
	justify-content: space-around;
	width: 300px;
	margin-top: 20px;
	margin: 20px auto 0 auto;
}

.swipeable-buttons-container .swipe-popin {
	background: #37249b;
	color: white;
	position: absolute;
	top: -11%;
	box-shadow: 0 2px 8px #615555;
	padding: 1px 6px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.swipeable-buttons-container .swipe-popin.liked {
	right: -15%;
	border-bottom-right-radius: 10px;
	transform: rotate(15deg);
	animation: spinRight 1s linear forwards;
}

.swipeable-buttons-container .swipe-popin.unliked {
	border-bottom-left-radius: 10px;
	left: -15%;
	transform: rotate(-15deg);
	animation: spinLeft 1s linear forwards;
}

.behind-card {
	position: absolute;
	top: 55px;
	left: 12px;
	z-index: -1;
}

.progress-bar-game {
	height: 10px;
	background: #9c8ed9;
	transition: width 1s ease-in-out;
}

.progress-bar-game.progress-0 {
	width: 0%;
}

.progress-bar-game.progress-1 {
	width: 1%;
}

.progress-bar-game.progress-2 {
	width: 2%;
}

.progress-bar-game.progress-3 {
	width: 3%;
}

.progress-bar-game.progress-4 {
	width: 4%;
}

.progress-bar-game.progress-5 {
	width: 5%;
}

.progress-bar-game.progress-6 {
	width: 6%;
}

.progress-bar-game.progress-7 {
	width: 7%;
}

.progress-bar-game.progress-8 {
	width: 8%;
}

.progress-bar-game.progress-9 {
	width: 9%;
}

.progress-bar-game.progress-10 {
	width: 10%;
}

.progress-bar-game.progress-11 {
	width: 11%;
}

.progress-bar-game.progress-12 {
	width: 12%;
}

.progress-bar-game.progress-13 {
	width: 13%;
}

.progress-bar-game.progress-14 {
	width: 14%;
}

.progress-bar-game.progress-15 {
	width: 15%;
}

.progress-bar-game.progress-16 {
	width: 16%;
}

.progress-bar-game.progress-17 {
	width: 17%;
}

.progress-bar-game.progress-18 {
	width: 18%;
}

.progress-bar-game.progress-19 {
	width: 19%;
}

.progress-bar-game.progress-20 {
	width: 20%;
}

.progress-bar-game.progress-21 {
	width: 21%;
}

.progress-bar-game.progress-22 {
	width: 22%;
}

.progress-bar-game.progress-23 {
	width: 23%;
}

.progress-bar-game.progress-24 {
	width: 24%;
}

.progress-bar-game.progress-25 {
	width: 25%;
}

.progress-bar-game.progress-26 {
	width: 26%;
}

.progress-bar-game.progress-27 {
	width: 27%;
}

.progress-bar-game.progress-28 {
	width: 28%;
}

.progress-bar-game.progress-29 {
	width: 29%;
}

.progress-bar-game.progress-30 {
	width: 30%;
}

.progress-bar-game.progress-31 {
	width: 31%;
}

.progress-bar-game.progress-32 {
	width: 32%;
}

.progress-bar-game.progress-33 {
	width: 33%;
}

.progress-bar-game.progress-34 {
	width: 34%;
}

.progress-bar-game.progress-35 {
	width: 35%;
}

.progress-bar-game.progress-36 {
	width: 36%;
}

.progress-bar-game.progress-37 {
	width: 37%;
}

.progress-bar-game.progress-38 {
	width: 38%;
}

.progress-bar-game.progress-39 {
	width: 39%;
}

.progress-bar-game.progress-40 {
	width: 40%;
}

.progress-bar-game.progress-41 {
	width: 41%;
}

.progress-bar-game.progress-42 {
	width: 42%;
}

.progress-bar-game.progress-43 {
	width: 43%;
}

.progress-bar-game.progress-44 {
	width: 44%;
}

.progress-bar-game.progress-45 {
	width: 45%;
}

.progress-bar-game.progress-46 {
	width: 46%;
}

.progress-bar-game.progress-47 {
	width: 47%;
}

.progress-bar-game.progress-48 {
	width: 48%;
}

.progress-bar-game.progress-49 {
	width: 49%;
}

.progress-bar-game.progress-50 {
	width: 50%;
}

.progress-bar-game.progress-51 {
	width: 51%;
}

.progress-bar-game.progress-52 {
	width: 52%;
}

.progress-bar-game.progress-53 {
	width: 53%;
}

.progress-bar-game.progress-54 {
	width: 54%;
}

.progress-bar-game.progress-55 {
	width: 55%;
}

.progress-bar-game.progress-56 {
	width: 56%;
}

.progress-bar-game.progress-57 {
	width: 57%;
}

.progress-bar-game.progress-58 {
	width: 58%;
}

.progress-bar-game.progress-59 {
	width: 59%;
}

.progress-bar-game.progress-60 {
	width: 60%;
}

.progress-bar-game.progress-61 {
	width: 61%;
}

.progress-bar-game.progress-62 {
	width: 62%;
}

.progress-bar-game.progress-63 {
	width: 63%;
}

.progress-bar-game.progress-64 {
	width: 64%;
}

.progress-bar-game.progress-65 {
	width: 65%;
}

.progress-bar-game.progress-66 {
	width: 66%;
}

.progress-bar-game.progress-67 {
	width: 67%;
}

.progress-bar-game.progress-68 {
	width: 68%;
}

.progress-bar-game.progress-69 {
	width: 69%;
}

.progress-bar-game.progress-70 {
	width: 70%;
}

.progress-bar-game.progress-71 {
	width: 71%;
}

.progress-bar-game.progress-72 {
	width: 72%;
}

.progress-bar-game.progress-73 {
	width: 73%;
}

.progress-bar-game.progress-74 {
	width: 74%;
}

.progress-bar-game.progress-75 {
	width: 75%;
}

.progress-bar-game.progress-76 {
	width: 76%;
}

.progress-bar-game.progress-77 {
	width: 77%;
}

.progress-bar-game.progress-78 {
	width: 78%;
}

.progress-bar-game.progress-79 {
	width: 79%;
}

.progress-bar-game.progress-80 {
	width: 80%;
}

.progress-bar-game.progress-81 {
	width: 81%;
}

.progress-bar-game.progress-82 {
	width: 82%;
}

.progress-bar-game.progress-83 {
	width: 83%;
}

.progress-bar-game.progress-84 {
	width: 84%;
}

.progress-bar-game.progress-85 {
	width: 85%;
}

.progress-bar-game.progress-86 {
	width: 86%;
}

.progress-bar-game.progress-87 {
	width: 87%;
}

.progress-bar-game.progress-88 {
	width: 88%;
}

.progress-bar-game.progress-89 {
	width: 89%;
}

.progress-bar-game.progress-90 {
	width: 90%;
}

.progress-bar-game.progress-91 {
	width: 91%;
}

.progress-bar-game.progress-92 {
	width: 92%;
}

.progress-bar-game.progress-93 {
	width: 93%;
}

.progress-bar-game.progress-94 {
	width: 94%;
}

.progress-bar-game.progress-95 {
	width: 95%;
}

.progress-bar-game.progress-96 {
	width: 96%;
}

.progress-bar-game.progress-97 {
	width: 97%;
}

.progress-bar-game.progress-98 {
	width: 98%;
}

.progress-bar-game.progress-99 {
	width: 99%;
}

.progress-bar-game.progress-100 {
	width: 100%;
}

.progress-bar-game.rounded {
	border-radius: 15px;
}

.rounded-parent {
	width: 90%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	background: #d1d1d161;
	border-radius: 15px;
}

@keyframes coloredThenOpacity {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

.notification-container {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	background-color: #e0ce2f;
	position: relative;
	cursor: pointer;
	opacity: 0.5;
	animation: 5s linear 0s 1 coloredThenOpacity;
	transition: opacity 0.1s linear;
}

.notification-container:hover {
	opacity: 1;
}

.notification-container.in-progress {
	background-color: #e0ce2f;
}

.notification-container.done {
	background-color: #209a0a;
}

.notification-container.error {
	background-color: #880000;
}

.notification-container.waiting {
	background-color: #e68900;
}

.notification-container .top-container {
	display: flex;
	justify-content: space-between;
	padding: 5px;
}

.notification-container .top-container i.remove {
	opacity: 0.5;
}

.notification-container .top-container i.remove:hover {
	opacity: 1;
}

.notification-container .bottom-container {
	padding: 0px 5px 10px 5px;
}

.notification-container .bottom-container .description {
	flex-grow: 1;
	text-align: center;
	font-size: small;
	line-height: 1;
}

.ui.form.unavailable {
	filter: opacity(0.5);
}

.ui.form.unavailable:after {
	content: ' ';
	display: block;
	background: rgba(1, 1, 1, 0.2);
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 10px;
	cursor: not-allowed;
}

.limit-container {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	animation: appears forwards 1s linear;
}

.limit-container .upgrade-button-container {
	display: inline-block;
	position: relative;
}

.limit-container .upgrade-button-container button {
	width: 365px;
}

.limit-container .upgrade-button-container svg {
	position: absolute;
	right: -5%;
	top: 14%;
}

.limit-container .limit {
	color: white;
	background-color: #4e54c8;
	border-radius: 15px;
	padding: 15px;
}

.limit-container .overlay-icon {
	position: relative;
}

.form-error-ul li:before {
	color: #9f3a38 !important;
}

.answers-container .card .hidden {
	opacity: 0;
}

.answers-container .card:not(.unavailable):hover .hidden {
	opacity: 1;
	display: initial;
}

.answers-container .card.unavailable {
	cursor: not-allowed;
}

.answers-container .card.unavailable .title {
	background-color: #eeeff0 !important;
	border: dashed 1px #5a5a66;
	color: #5a5a66 !important;
}

.answers-container .card:not(.unavailable):hover .title {
	background-color: #4e54c8;
	color: white !important;
	border: none;
}

.answers-container .card:not(.unavailable).selected .title {
	background-color: #4e54c8;
	color: white !important;
	border: none;
}

.answers-container .card {
	width: 33%;
	border-radius: 5px;
	margin-right: 15px;
	cursor: pointer;
}

.answers-container.with-test .card {
	width: 27%;
}

.answers-container.with-test .card:first-child {
	width: 19%;
}

.answers-container.with-test .card:first-child .content {
	margin: 10px;
}

.answers-container.with-test .card .test-title {
	margin: 0;
	text-align: left;
}

.answers-container.with-test .card .title {
	margin-top: 0;
}

.answers-container .card:last-child {
	margin-right: 0;
}

.answers-container .card .title {
	border-radius: 5px;
	padding: 10px;
	border: dashed 1px #4e54c8;
	background-color: rgba(78, 84, 200, 0.1);
}

.answers-container .card .content {
	margin: 25px 10px;
}

.answers-container .card .footer {
	border: dashed 1px #4e54c8;
	background-color: white;
	border-radius: 5px;
	display: inline-block;
	padding: 5px 15px;
	margin-top: 0px;
}

.icon-answers {
	margin-top: 75px;
}

@keyframes height-animated {
	from {
		height: 0px;
	}
	to {
		height: 5px;
	}
}

.answers-container .line {
	height: 0px;
	background: #4e54c8;
	border-radius: 10px;
	width: 72%;
	position: absolute;
	right: 5%;
}

.answers-container .line.full {
	height: 5px;
	animation: height-animated 0.1s ease-in;
}

.answers-container .arrow {
	margin: auto;
	width: 0;
	height: 0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-top: 11px solid #4e54c8;
	margin-bottom: 10px;
}

.answers-container .upgrade-button-container {
	display: inline-block;
	position: relative;
}

.answers-container .upgrade-button-container svg {
	position: absolute;
	right: -29px;
	top: -4px;
}

.offer-container {
	height: 100%;
}

.offer-container .card {
	max-width: 335px;
	border-radius: 5px;
	margin-right: 15px;
	width: 33%;
	height: 100%;
	max-height: 565px;
	overflow: hidden;
}

.offer-container .card:hover,
.offer-container .card.selected {
	max-height: none;
}

.offer-container .card:nth-child(3) {
	margin-right: 0;
}

.card .subtitle {
	font-size: 12px;
	font-weight: 500;
	color: white;
}

.offer-container .card .header-card {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-top: solid 1px rgba(78, 84, 200, 0.05);
	border-left: solid 1px rgba(78, 84, 200, 0.05);
	border-right: solid 1px rgba(78, 84, 200, 0.05);
}

.offer-container .card .footer {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom: solid 1px rgba(78, 84, 200, 0.05);
	border-left: solid 1px rgba(78, 84, 200, 0.05);
	border-right: solid 1px rgba(78, 84, 200, 0.05);
	text-align: left;
	padding: 10px;
}

.offer-container .card .content {
	padding: 25px;
	text-align: left;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background: rgba(78, 84, 200, 0.05);
}

.offer-container .card .content .subtitle {
	margin-bottom: 0;
}

.offer-container .card .content svg {
	margin-top: 25px;
}

.offer-container .card .footer .subtitle {
	margin-bottom: 0;
}

.offer-container .card.plug .content {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.offer-container .card.plug .footer {
	position: relative;
}

.offer-container .card.plug .footer svg {
	position: absolute;
	top: -50px;
	right: 20px;
}

.offer-container .card.selected {
	box-shadow: 0 2px 8px #bbb;
}

.offer-container .card.selected .header-card,
.offer-container .card.selected .footer {
	background-color: #4e54c8;
}

.offer-container .card.selected .header-card .header,
.offer-container .card.selected .header-card .subtitle,
.offer-container .card.selected .footer .header,
.offer-container .card.selected .footer .subtitle {
	color: white !important;
}

.offer-container
	.card.selected
	.header-card
	.ui.bulleted.list
	.list
	> .item:before,
.offer-container .card.selected .header-card .ui.bulleted.list > .item:before,
.offer-container .card.selected .header-card ul.ui.list li:before,
.offer-container .card.selected .footer .ui.bulleted.list .list > .item:before,
.offer-container .card.selected .footer .ui.bulleted.list > .item:before,
.offer-container .card.selected .footer ul.ui.list li:before {
	content: '°';
}

.offer-container .card.selected .content {
	border: dashed 1px #4e54c8;
	background: rgba(78, 84, 200, 0.1);
}

.offer-container .card:hover {
	box-shadow: 0 2px 8px #bbb;
	cursor: pointer;
}

.offer-container .card:hover .header-card {
	border-top: dashed 1px #4e54c8;
	border-left: dashed 1px #4e54c8;
	border-right: dashed 1px #4e54c8;
}

.offer-container .card:hover .content {
	border: dashed 1px #4e54c8;
	background: rgba(78, 84, 200, 0.1);
}

.offer-container .card:hover .footer {
	border-bottom: dashed 1px #4e54c8;
	border-left: dashed 1px #4e54c8;
	border-right: dashed 1px #4e54c8;
}

.type-container {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	width: 610px;
	max-height: 100%;
}

.type-container .survey-card {
	background-color: rgba(78, 84, 200, 0.1);
	padding: 0 30px;
	color: black;
	border: dashed 1px #4e54c8;
}

.type-container .survey-card:not(.meet) {
	width: 290px;
	height: 265px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.type-container .survey-card .subsubtitle {
	font-size: 11px;
}

.type-container .survey-card svg {
	align-self: center;
}

.type-container .survey-card.unavailable {
	filter: grayscale(90%);
}

.type-container .survey-card.unavailable .unavailable-sticker {
	font-size: smaller;
	position: absolute;
	right: 4px;
	background: navajowhite;
	top: -20px;
	padding: 1px 5px;
	border-radius: 5px;
	color: black;
	font-weight: 600;
}

.type-container .survey-card:hover:not(.unavailable):not(.meet) {
	background-color: #4e54c8;
	border: none;
}

.type-container .survey-card:hover:not(.unavailable):not(.meet) > * {
	color: white !important;
}

.type-container .survey-card:hover:not(.unavailable):not(.meet) .header {
	background-color: #4e54c8;
	color: white !important;
}

.type-container .survey-card.selected {
	background-color: #4e54c8;
	border: none;
}

.type-container .survey-card.selected > * {
	color: white !important;
}

.type-container .survey-card.selected .header {
	background-color: #4e54c8;
	color: white !important;
}

.type-container .survey-card > *:first-child {
	margin: 10px 0 5px 0 !important;
}

.type-container .survey-card > *:last-child {
	margin: 5px 0 !important;
}

.type-container .survey-card > * {
	margin: 5px 0 !important;
}

.type-container .survey-card.meet {
	border: none;
	padding: 5px 0;
	cursor: default;
	margin-top: 15px;
}

.animated-rater {
	transform: translateZ(0);
}

.animated-rater .react-rater > * {
	margin-left: 1rem;
}

.animated-rater svg {
	cursor: pointer;
}

.game-container .top-container {
	position: fixed;
	width: 100%;
	z-index: 1001;
	margin: 5px 0 0 0 !important;
	left: 0;
}

.game-container .top-row {
	padding: 0px !important;
}

.big-buttons .ant-slider-handle {
	width: 60px;
	height: 60px;
	margin-top: -30px;
}

/* buttons */
.ui.light-btn {
	background-color: #fff5ee;
}

.ui.light-btn:hover {
	background-color: #fff5ee;
}

.ui.light-btn:active {
	background-color: #fff5ee;
}

.ui.light-btn:focus {
	background-color: #fff5ee;
}

.ui.colored-btn {
	background-color: #ffdead;
}

.ui.colored-btn:hover {
	background-color: #ffdead;
	color: #66625f;
}

.ui.colored-btn:active {
	background-color: #ffdead;
}

.ui.colored-btn:focus {
	background-color: #ffdead;
}

.button.add-mail {
	background: #673ab7 !important;
	color: white !important;
}

.button.add-mail.connected {
	background: #e91e63 !important;
	color: white !important;
}

.button.add-mail.connected:hover {
	color: white !important;
	background: #e91e63 !important;
	opacity: 0.8 !important;
}

.button.add-mail:hover {
	color: white !important;
	background: #673ab7 !important;
	opacity: 0.8 !important;
}

/* bottom */
.next-button:hover {
	background: #fbbc5e;
}

.next-button:hover .next-icon {
	padding-left: 40%;
	transition: padding-left 0.5s ease-out;
}

.next-icon {
	padding-left: 0%;
	transition: padding-left 0.5s ease-out;
}

.bottom-text-component {
	text-align: left;
}

.next-text-container {
	margin-top: 0.5em;
}

@media only screen and (min-width: 768px) {
	.next-text-container {
		margin-top: 1em;
	}
	.bottom-text-component {
		margin-left: 45vw;
	}
	.brand-logo {
		width: 100px !important;
	}
}

@media only screen and (max-width: 767px) {
	.next-text-container {
		margin-top: 0.5em;
	}
	.bottom-text-component {
		margin-left: 26vw;
	}
	.brand-logo {
		width: 70px !important;
	}
}

@keyframes size-animated {
	from {
		margin-top: 0px;
	}
	50% {
		margin-top: 10px;
	}
	to {
		margin-top: 0px;
	}
}

.scroll-to-bottom-icon {
	animation: size-animated 1s ease-in infinite;
	margin-top: 0px;
	transition: margin-top 1s ease-in;
}

.scroll-to-bottom-container {
	cursor: pointer;
	position: fixed;
	bottom: 16vh;
	right: 2vw;
	height: 50px;
}

.scroll-to-bottom-container:hover {
	color: navajowhite;
}

.scroll-to-bottom-container:hover .scroll-to-bottom-icon {
	animation: none;
	margin-top: 10px;
	transition: margin-top 1s ease-in;
}

.progress-bar {
	background: #afef2d;
	height: 4px;
	transition: width 300ms linear;
}

.progress {
	color: #5d5858 !important;
}

.mb-3 {
	margin-bottom: 3px !important;
}

.progress-bar.progress-0 {
	width: 5%;
}

.progress-bar.progress-25 {
	width: 25%;
}

.progress-bar.progress-50 {
	width: 50%;
}

.progress-bar.progress-75 {
	width: 75%;
}

.progress-bar.progress-100 {
	width: 100%;
}

.progress-0 .bar {
	background-color: #d4d1cf !important;
}

.progress-25 .bar {
	background-color: #fffafe !important;
}

.progress-50 .bar {
	background-color: #fdf5e3 !important;
}

.progress-75 .bar {
	background-color: #bff1e6 !important;
}

.progress-100 .bar {
	background-color: #a5f80b !important;
}

/* images */
.item-materials-container {
	display: flex;
	justify-content: center;
}

.item-materials-container .label {
	margin-right: 10px;
	color: #ffffff;
}

.item-materials-container .label:first-child {
	background: #ffaaaa;
}

.item-materials-container .label:nth-child(2) {
	background: #fdf3be;
	color: #565656;
}

.item-materials-container .label:nth-child(3) {
	background: thistle;
}

.item-label-container {
	display: flex;
	justify-content: space-around;
	margin: 3px auto;
	/* margin: 0px */
}

@media only screen and (max-width: 766px) {
	.item-label-container {
		width: 200px;
	}
}

@media only screen and (min-width: 767px) {
	.item-label-container {
		width: 400px;
	}
}

.item-label {
	background: #fffafe !important;
}

@keyframes m-l-animated {
	from {
		margin-left: 0px;
	}
	50% {
		margin-left: 10px;
	}
	to {
		margin-left: 0px;
	}
}

@keyframes m-r-animated {
	from {
		margin-right: 0px;
	}
	50% {
		margin-right: 10px;
	}
	to {
		margin-right: 0px;
	}
}

.slider-icon-container {
	width: 25px;
}

.slider-icon-container.right {
	text-align: left;
}

.slider-icon-container.left {
	text-align: right;
}

.slider-icon {
	margin: 0 !important;
	font-size: 15px !important;
}

.slider-icon.right.animated {
	animation: m-r-animated 1s ease-in infinite;
	transition: margin-right 1s ease-in;
}

.slider-icon.left.animated {
	animation: m-l-animated 1s ease-in infinite;
	transition: margin-left 1s ease-in;
}

.card-price {
	font-size: 3rem !important;
	margin-top: 0 !important;
	text-align: center;
}

@keyframes top-animated {
	from {
		top: 50%;
	}
	50% {
		top: 48%;
	}
	to {
		top: 50%;
	}
}

.chrono-left {
	display: inline-block;
	padding: 5px 31px;
	background: #3a3a3a;
	color: whitesmoke;
	font-size: medium;
	cursor: pointer;
	margin: auto;
}

.chrono-left-container {
	width: 100%;
	left: 0;
	position: fixed;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	animation: top-animated 1s ease-in-out infinite;
	transition: transform 2.5s ease-in;
}

.chrono-left-background {
	position: fixed;
	top: 0;
	z-index: 10000;
	background-color: white;
	opacity: 0.85;
	transition: opacity 2.5s ease-in;
	width: 100%;
	height: 100%;
	left: 0;
}

.chrono-left-background.hide {
	opacity: 0;
}

/* Center */
.description-medium {
	font-weight: bold;
	font-size: 14px;
	display: contents;
}

.game-description-item {
	box-shadow: 0 1px 2px #bbb;
	border-radius: 5px;
	margin-top: 9vh;
	padding: 0 8vw;
	height: 18vh;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 766px) {
	.game-description-item {
		bottom: 21.5vh;
	}
}

@media only screen and (min-width: 767px) {
	.game-description-item {
		height: 120px;
		bottom: 22vh;
	}
	.item-materials-container-absolute {
		margin-top: 9vh;
	}
}

.mt-5 {
	margin-top: 4vh !important;
}

.column-direction {
	flex-direction: column !important;
}

.bold {
	font-weight: 700;
}

.with-bg-style {
	background-image: url('https://i.stack.imgur.com/wPh0S.jpg');
	width: 200px;
	height: 100px;
	background-position: center;
	background-size: cover;
}

.list-location-error {
	display: table;
	margin-left: auto !important;
	margin-right: auto !important;
}

.next-game-container .card {
	border-radius: 5px;
	padding: 3px;
	border: solid 1px #4e54c8;
	width: 150px;
	height: 150px;
	position: relative;
	box-shadow: 0 1px 4px #bbb;
}

.next-game-container .card:not(:last-child) {
	margin-right: 5px;
}

.next-game-container .card:hover {
	cursor: pointer;
	box-shadow: 0 4px 16px #bbb;
}

.next-game-container .card .top-image {
	margin-top: 0 !important;
}

.next-game-container .card .image {
	margin-top: 2px;
	margin-bottom: 2px;
}

.next-game-container .card .image.height-limit {
	max-height: 55px;
}

.next-game-container .card .play {
	color: white;
	background-color: #4e54c8;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.slider-label .minus-container {
	margin-right: 35px;
}

.slider-label .plus-container,
.slider-label .minus-container {
	padding: 15px;
	border-radius: 10px;
	background-color: #f5f5f5;
	box-shadow: 0 2px 8px #bbb;
}

.slider-label .plus-container > *,
.slider-label .minus-container > * {
	margin: 0;
}

.slider-label .plus-container:hover,
.slider-label .minus-container:hover {
	background: #b2dbff;
}

.container-reports {
	display: flex;
	position: absolute;
	color: black;
	right: 2%;
	top: -3%;
}

.container-reports > div {
	font-size: smaller;
	margin-left: 2px;
	color: #4e54c8;
	border: solid 1px #4e54c8;
	background: white;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
}

.container-reports > div:hover {
	background: #f1f1f1;
}

.container-reports span {
	display: none;
}

.report-container:hover > span {
	display: initial;
}

.age-range-container {
	height: 100vh;
	flex-direction: column;
}

.age-range-container > .centered-container {
	margin-bottom: 8px;
}

.age-range-container > .centered-container > :first-child {
	margin-right: 8px;
}

.age-range-container > .centered-container .card:hover,
.age-range-container > .centered-container .selected {
	background: #b2dbff;
	box-shadow: 0 2px 8px #bbb;
}

.age-range-container .card {
	cursor: pointer;
	background: aliceblue;
	border-radius: 5px;
	box-shadow: 0 0.25px 1px #bbb;
	font-size: x-large;
	font-weight: 500;
	width: 170px;
	height: 140px;
}

.gender-container {
	height: 100vh;
}

.gender-container .male.icon {
	color: #54abf1;
}

.gender-container .female.icon {
	color: pink;
}

.gender-container .centered-container > :first-child {
	margin-right: 8px;
}

.gender-container .centered-container .card:hover,
.gender-container .centered-container .selected {
	background: #b2dbff;
	box-shadow: 0 2px 8px #bbb;
}

.gender-container .centered-container .card {
	cursor: pointer;
	background: aliceblue;
	border-radius: 5px;
	box-shadow: 0 0.25px 1px #bbb;
	font-size: x-large;
	font-weight: 500;
	width: 170px;
	height: 140px;
}

.game-stock-container .top-container-stock {
	position: fixed;
	width: 100vw;
	text-align: center;
}

.game-stock-container .top-container-stock img,
.game-stock-container .top-container-stock .brand-name {
	margin-top: 11px;
}

.game-stock-container .content-container {
	width: 100vw;
	height: 100vh;
	padding-top: 10px;
}

.game-stock-container .progress-bar-container {
	position: fixed;
	bottom: 0;
	text-align: left;
	z-index: -1;
}

@keyframes twinkle {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.new-game-stock-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	min-height: 100vh;
	margin: 0 10px;
	padding-bottom: 15px;
	position: relative;
}

.new-game-stock-container .start-game-button-arrow {
	animation: twinkle infinite 2s ease;
}

.new-game-stock-container .start-game-button {
	box-shadow: 0 2px 8px #bbb !important;
}

.new-game-stock-container .image-brand {
	max-height: 60px;
	width: auto;
}

.new-game-stock-container .ui.label {
	padding: 0.4em 0.7em;
}

.new-game-stock-container .description {
	max-width: 275px;
}

.new-game-stock-container .subtitle-button {
	font-size: 0.81rem;
	margin-top: 5px;
}

.new-game-stock-container .bottom-fixed {
	position: absolute !important;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 5px;
}

.new-game-stock-container .link-button-container {
	margin-bottom: 10px;
}

.new-game-stock-container .link-button-container .link-button {
	color: white;
}

.new-game-stock-container .thanks-label {
	background-color: #4a3ca7;
	color: white;
	padding: 10px 40px !important;
}

.new-game-stock-container .subtitle-promo {
	font-size: 0.8rem;
	font-weight: 500;
}

.container-guides {
	justify-content: space-evenly;
	display: flex;
	margin: 50px;
	flex-wrap: wrap;
}

.container-guide-card {
	width: 200px;
	height: 200px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	margin-top: 25px;
}

.container-guide-card .title {
	padding: 15px;
	flex: 1;
	border-radius: 5px 5px 0px 0px;
}

.container-guide-card .body {
	margin: 15px;
	display: flex;
	flex: 9;
	flex-direction: column;
	position: relative;
}

.container-guide-card .description {
	overflow: auto;
	max-height: -webkit-fill-available;
}

.container-guide-card button,
.container-guide-card a {
	align-self: center;
	vertical-align: bottom;
	position: absolute;
	bottom: 0;
}

.mt-2em {
	margin-top: 2em;
}

.mh-2em {
	margin-top: 2em;
	margin-bottom: 2em;
}

.opacity-4 {
	opacity: 0.4;
}

.opacity-0 {
	opacity: 0;
}

.nav-menu h4 {
	margin: 0 2em !important;
}

.nav-menu h4:not(.opacity-4) {
	cursor: pointer;
}

.nav-menu h4:hover {
	text-decoration: underline;
}

.nav-menu h4.opacity-4:hover {
	text-decoration: none;
}

.nav-menu h4.opacity-4 {
	margin-right: 0 !important;
}

.nav-menu .icon {
	writing-mode: vertical-lr;
	-webkit-writing-mode: vertical-lr;
}

.bg-transparent {
	background: rgba(78, 84, 200, 0.1) !important;
	color: #4e54c8;
}

.section {
	position: relative;
	background: rgba(78, 84, 200, 0.1) !important;
	color: #4e54c8;
}

.section.shadow {
	-webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.card.shadow {
	-webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.section.full-width {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	height: 160px;
}

.section.padding {
	padding: 15px 20px 15px 20px;
}

.section .title {
	text-align: left;
	display: flex;
}

.section .title h4 {
	margin: 0;
}

.section .title span {
	margin-left: 4px;
	line-height: 1.1;
}

.section .title .span {
	margin-left: 4px;
}

.section .body {
	margin-top: 0.5em;
	height: 85%;
	display: flex;
	justify-content: space-evenly;
}

.section .card {
	width: 24%;
	height: 100%;
	background: white;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
}

.section .card .reinit {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	cursor: pointer;
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.section .card .reinit.reload {
	animation: rotating 0.5s linear;
	animation-iteration-count: 1;
}

.section .card.padding {
	padding: 10px;
}

.card p.padding {
	padding: 10px;
}

.card p.description {
	font-size: 13px;
	font-weight: 600;
}

p.sub-description {
	font-size: 11px;
	font-weight: 600;
	margin-bottom: 3px;
	text-align: center;
}

.section .card .header {
	margin: 0 0 3px 0;
}

.section .card .header.double-margin {
	margin: 0 0 10px 0;
}

.section .card.divided {
	flex-direction: row;
}

.section .card.divided > div {
	padding: 10px 0;
	width: 49%;
}

.section .card.divided > div .header {
	margin: 0;
}

.section .card .icon {
	margin-bottom: 10px;
	color: #4e54c8;
}

.section .card .color-white .icon {
	color: white;
}

.section .card .icon.female {
	color: #edb9cd;
}

.section .card .survol {
	cursor: pointer;
	border-radius: 0px 5px 0px 5px;
}

.section .survol:hover {
	background: rgba(78, 84, 200, 0.2);
}

.section .survol.selected {
	background: rgba(78, 84, 200, 0.5);
}

.section .card .slider-size {
	width: 90%;
}

.section .card .title-fixed {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.section .card .locations-container {
	width: 100%;
	overflow: auto;
	margin-top: 25px;
}

.section .card .location-container {
	cursor: pointer;
	display: flex;
	margin-bottom: 5px;
	border-bottom: 1px solid #4e54c8;
}

.section .card .location-container > * {
	margin-right: 5px;
}

.section.filter {
	margin-bottom: 20px;
}

.button .icon {
	margin-left: 10px !important;
}

.button .icon.reload {
	animation: rotating 0.5s linear;
	animation-iteration-count: 1;
}

.graphs-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	/* margin-left: auto */
	/* margin-right: auto */
	/* margin-top: 30px */
}

@media only screen and (min-width: 1540px) {
	.graphs-container {
		justify-content: space-evenly;
	}
}

.graphs-container > div {
	margin-top: 30px;
	margin-left: 2.5%;
}

.graphs-container .card.optimal-price {
	width: 27%;
	margin-left: auto;
	margin-right: auto;
}

.graphs-container .card.optimal-price .title {
	padding: 25px 0;
	height: 70%;
}

.graphs-container .card.optimal-price .body {
	height: 30%;
}

.graphs-container .card {
	border-radius: 5px;
	position: relative;
}

.graphs-container .card .title {
	border-radius: 5px 5px 0 0;
}

.graphs-container .card .title h3 {
	padding: 5px;
}

.graphs-container .card h6.padding {
	padding: 10px;
}

.graphs-container .card .body {
	border-radius: 0 0 5px 5px;
}

h1.bigger {
	font-size: -webkit-xxx-large !important;
}

.zoom {
	position: absolute;
	right: 0;
	bottom: 0;
}

.zoom-modal-container .card {
	margin: 10px 0;
}

.zoom-modal-container .card.unique {
	margin: 10px auto;
}

.card .zoom-modal-container {
	position: relative;
}

.graphs-container .section {
	height: 450px;
	max-width: 850px;
}

.graphs-container .card {
	height: 450px;
	max-width: 325px;
}

.section .graph {
	width: 100%;
	height: 55%;
}

.section .graph.big {
	border-radius: 5px 5px 0 0;
	height: 70%;
}

.section .graph.big.divided {
	display: flex;
}

.section .graph.big.divided > div {
	width: 50%;
}

.section.graph .card-container {
	width: 90%;
	margin-top: 5px;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
}

.upgrade-label {
	position: absolute;
	text-align: center;
	color: #f0f0f0;
	top: 25px;
	z-index: 10;
	right: -35px;
	display: block;
	width: 150px;
	background: #4e54c8;
	padding: 0.25em 0;
	transform: rotate(45deg);
}

.section.graph .card {
	height: 20%;
	width: 48%;
}

.section.gabor {
	margin-left: auto;
	margin-right: auto;
	width: 65.5%;
}

.section {
	border-radius: 5px;
}

.section.map {
	width: 60%;
}

.section .left.title .header {
	margin: 0;
}

.section .left.title {
	padding: 1px 0px 0 10px;
}

.section .left.title h5 {
	line-height: 1.7;
	margin-left: 3px !important;
}

.map-container {
	width: 100%;
	height: 75%;
	max-height: 260px;
	background: white;
}

.map-container > svg {
	max-height: 260px;
}

.value {
	background-color: white;
	border-radius: 5px;
	padding: 2px;
	display: flex;
	width: 26%;
	margin-left: 3%;
	margin-top: 2px;
}

.value div:first-child {
	width: 25%;
}

.value div:nth-child(2) {
	text-align: left;
	width: 75%;
}

.value .header {
	margin: 0;
}

.section.keyword {
	width: 32.5%;
	/*ci-dessous à virer lorsque la map revient*/
	margin-left: auto;
	margin-right: auto;
}

.chart-container {
	height: 90%;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.vertical-center {
	margin-left: auto;
	margin-right: auto;
}

.container-item-page {
	margin-bottom: 30vh;
}

button.more {
	margin-top: 3em !important;
}

.graph .title-graph {
	padding-left: 10px;
	padding-top: 10px;
}

.graph .title-graph .header {
	margin: 0;
	text-align: left;
}

.gabor .max-height-chart {
	max-height: 280px;
}

.gabor.elasticity .max-height-chart {
	max-height: 260px;
}

.tooltip-card {
	width: 50px;
	height: 50px;
	background-color: khaki;
}

.donut-max-height {
	height: 300px;
}

.upgrade-button-card-container {
	position: absolute;
	bottom: -18px;
	left: 50%;
}

.upgrade-button-card-container > div {
	left: -50%;
}

.donut-center {
	position: absolute;
	left: 25%;
	top: 30.5%;
	width: 50%;
	height: 40%;
	border-radius: 50%;
	/* background: white */
	/* box-shadow: 0 2px 8px #bbb */
	flex-direction: column;
}

.donut-center .color-blue {
	margin-bottom: 2px !important;
	width: 75%;
}

.donut-container {
	position: relative;
}

/* .shadow-new
 box-shadow: 0 2px 8px #bbb
 */
.overview-container {
	display: flex;
	flex-direction: column;
	max-width: 1800px;
	margin: 0 auto;
	padding: 0 10px;
}

.overview-container .top {
	display: flex;
}

.overview-container h4 {
	margin-top: 0 !important;
	margin-bottom: 4px !important;
}

.overview-container .ui.container {
	width: initial;
}

.overview-container .keywords-container.materials {
	margin-bottom: 20px;
}

.overview-container .card {
	padding: 10px;
	background: white;
	border-radius: 5px;
	display: inline-block;
	position: relative;
	border: solid 1px #4e54c8;
	text-align: center;
}

.overview-container .cards {
	display: flex;
	justify-content: space-around;
}

.overview-container .description-container {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	margin-left: 5px;
}

.overview-container .item.description-container {
	background: rgba(78, 84, 200, 0.5);
	color: white;
}

.overview-container .card-container {
	display: flex;
	justify-content: space-around;
}

.overview-container .card-container .card-actual {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 20px 35px;
	background: rgba(78, 84, 200, 0.1);
	border-radius: 5px;
	position: relative;
	text-align: center;
}

.card-actual.shadow {
	box-shadow: 0 2px 8px #bbb;
}

.overview-container .interaction-area {
	background: rgba(78, 84, 200, 0.15);
	border-radius: 5px;
	padding: 10px 20px;
}

.card-category {
	margin-top: 57px !important;
	margin-bottom: 20px !important;
}

.limited-card-category {
	margin: 10px 0px 12px !important;
}

.field .text-container {
	width: 200px;
}

.field .keywords-container {
	align-items: center;
}

.filter-toggle {
	position: absolute;
	right: 20px;
	display: inline-flex;
}

.percent-toggle {
	padding-right: 10px;
}

.number {
	padding-left: 10px;
}

.coupon-container > * {
	margin-right: auto;
	margin-left: auto;
}

.coupon-container .centered-container > *:first-child {
	margin-right: 10px !important;
}

.coupon-container .centered-container > *:last-child {
	margin-left: 10px !important;
}

.reward-container div .flex {
	flex-wrap: wrap;
}

.reward-container .survey-card {
	margin-top: 1em;
	background-color: rgba(78, 84, 200, 0.1);
	padding: 0 30px;
	color: black;
	border: dashed 1px #4e54c8;
	display: flex;
	flex-direction: column;
}

.reward-container .survey-card svg {
	align-self: center;
	margin: auto 0 5px 0 !important;
}

.reward-container .survey-card .subsubtitle {
	font-size: 12px;
}

.reward-container .survey-card:not(.meet) {
	width: 250px;
	height: 250px;
}

.reward-container .survey-card.meet {
	margin: 0 0 10px 0 !important;
}

.reward-container .survey-card.unavailable {
	filter: grayscale(90%);
	position: relative;
}

.reward-container .survey-card.unavailable .unavailable-sticker {
	font-size: smaller;
	position: absolute;
	right: 4px;
	background: navajowhite;
	top: -20px;
	padding: 1px 5px;
	border-radius: 5px;
	color: black;
	font-weight: 600;
}

.reward-container .survey-card:hover:not(.unavailable):not(.meet) {
	background-color: #4e54c8;
	border: none;
}

.reward-container .survey-card:hover:not(.unavailable):not(.meet) > * {
	color: white !important;
}

.reward-container .survey-card:hover:not(.unavailable):not(.meet) .header {
	background-color: #4e54c8;
	color: white !important;
}

.reward-container .survey-card.selected {
	background-color: #4e54c8;
	border: none;
}

.reward-container .survey-card.selected > * {
	color: white !important;
}

.reward-container .survey-card.selected .header {
	background-color: #4e54c8;
	color: white !important;
}

.reward-container .survey-card > *:first-child {
	margin: 10px 0 5px 0 !important;
}

.reward-container .survey-card > *:last-child:not(svg) {
	margin: 5px 0 !important;
}

.reward-container .survey-card > *:not(svg) {
	margin: 5px 0 !important;
}

.reward-container .survey-card.meet {
	border: none;
	padding: 5px 0;
	cursor: default;
	margin-top: 15px;
}

.recap-container .flex .left {
	margin-right: 5%;
}

.recap-container .flex .right {
	margin-top: 15%;
}

.recap-container .flex .right .subtitle {
	font-size: 12px;
	font-weight: 500;
}

.recap-container .flex .right .content {
	margin-left: 5px;
}

.recap-container .list-1 {
	margin-left: 4em;
	display: list-item;
	list-style-position: inside;
	list-style-type: disc;
}

.recap-container .list-2 {
	margin-left: 8em;
	display: list-item;
	list-style-position: inside;
	list-style-type: '- ';
}

.recap-container .list-3 {
	margin-left: 12em;
	display: list-item;
	list-style-position: inside;
	list-style-type: '- ';
}

.card-payment-container .price {
	background-color: white;
	padding: 0 30px;
	color: #4e54c8;
	border: dashed 1px #4e54c8;
	border-radius: 5px;
	display: inline-block;
	margin-bottom: 15px;
}

.va-middle {
	vertical-align: middle !important;
}

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
		rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
		rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.loading {
	height: 41.6px;
	opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
	padding: 0;
}

.error-payment {
	color: #ff6363 !important;
}

.card-payment-container {
	max-width: 925px !important;
}

.card-payment-container .subtitle {
	font-size: 12px;
	font-weight: 500;
	color: #4e54c8;
}

.card-payment-container > div {
	width: 50%;
}

.card-payment-container > div:first-child {
	margin-right: 2em;
}

.card-payment-container .credit-card-container {
	min-width: 400px;
	min-height: 400px;
	position: relative;
}

.card-payment-container .credit-card-container .credit-card {
	position: absolute;
	background-color: #ffadad;
	padding: 15px;
	color: black;
	border-radius: 5px;
	max-width: 250px;
	box-shadow: 0 2px 8px #bbb;
	cursor: pointer;
}

.card-payment-container .credit-card-container .credit-card:hover,
.card-payment-container .credit-card-container .credit-card.selected {
	box-shadow: 0 20px 80px #bbb;
}

.card-payment-container .credit-card-container .credit-card:nth-child(1) {
	left: 10px;
	top: 40px;
	background: #ffadad;
}

.card-payment-container .credit-card-container .credit-card:nth-child(1):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(1).selected {
	left: 100px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(2) {
	left: 20px;
	top: 80px;
	background: #b7ffad;
}

.card-payment-container .credit-card-container .credit-card:nth-child(2):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(2).selected {
	left: 110px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(3) {
	left: 30px;
	top: 120px;
	background: #adfff8;
}

.card-payment-container .credit-card-container .credit-card:nth-child(3):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(3).selected {
	left: 120px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(4) {
	left: 40px;
	top: 160px;
	background: #adb2ff;
}

.card-payment-container .credit-card-container .credit-card:nth-child(4):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(4).selected {
	left: 130px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(5) {
	left: 50px;
	top: 200px;
	background: #e6adff;
}

.card-payment-container .credit-card-container .credit-card:nth-child(5):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(5).selected {
	left: 140px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(6) {
	left: 60px;
	top: 240px;
	background: #ffadec;
}

.card-payment-container .credit-card-container .credit-card:nth-child(6):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(6).selected {
	left: 150px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .cardContainer {
	width: 370px;
}

.card-payment-container .sepaContainer {
	width: 330px;
}

.starter-offers-container .card:first-child {
	margin-right: 15px;
}

.starter-offers-container .card:last-child {
	background-color: #4e54c813;
}

.starter-offers-container .card {
	border: solid 2px #4e54c8;
	border-radius: 10px;
	padding: 30px 40px;
}

.starter-offers-container .card > * {
	margin: 20px 0 0 0;
}

.starter-offers-container .card .ui.radio.checkbox label:before {
	width: 30px;
	height: 30px;
	top: -5px;
	left: -10px;
	border: 2px solid #4e54c8;
}

.starter-offers-container .card .ui.radio.checkbox label:after {
	top: -6px;
	left: -11px;
	width: 32px;
	height: 33px;
	background-color: #4e54c8;
}

.starter-offers-container .card .subtitle {
	font-size: 15px;
	font-weight: 400;
}

.starter-offers-container .card .subtitle.unavailable {
	opacity: 0.3;
}

.audience-container .card .hidden {
	opacity: 0;
}

.audience-container .card:hover:not(.unavailable) .hidden {
	opacity: 1;
	display: initial;
}

.audience-container .card:hover:not(.unavailable) .title {
	background-color: #4e54c8;
	color: white !important;
	border: none;
}

.audience-container .card.selected .title {
	background-color: #4e54c8;
	color: white !important;
	border: none;
}

.audience-container .card {
	width: 33%;
	border-radius: 5px;
	margin-right: 15px;
	cursor: pointer;
}

.audience-container .card.unavailable {
	filter: grayscale(90%);
}

.audience-container .card.unavailable .unavailable-sticker {
	font-size: smaller;
	position: absolute;
	right: 4px;
	background: navajowhite;
	top: -9px;
	padding: 1px 5px;
	border-radius: 5px;
	color: black;
	font-weight: 600;
}

.audience-container .card.unavailable h3 {
	margin-top: 0;
}

.audience-container .card:last-child {
	margin-right: 0;
}

.audience-container .card .title {
	border-radius: 5px;
	padding: 10px;
	border: dashed 1px #4e54c8;
	background-color: rgba(78, 84, 200, 0.1);
}

.audience-container .card .content {
	margin: 25px 10px;
}

.audience-container .card .footer {
	border: dashed 1px #4e54c8;
	background-color: white;
	border-radius: 5px;
	display: inline-block;
	padding: 5px 15px;
	margin-top: 0px;
}

.audience-container .unavailable {
	cursor: not-allowed;
}

.audience-container .subtitle {
	font-size: 12px;
	font-weight: 500;
	color: black;
}

.audience-container .subtitle .flag {
	vertical-align: middle;
}

.audience-container .config-container > .flex > div {
	width: 33%;
}

.audience-container .config-container > .flex > div:not(:last-child) {
	margin-right: 5px;
}

.audience-container .config-container > .flex > div:nth-child(2) {
	margin-right: 15px;
}

.audience-container .config-container > .subtitle {
	max-width: 80%;
}

.audience-container .config-container .help {
	color: #545454;
	background: #e5e5f6;
	padding: 8px;
	border-radius: 3px;
	font-size: 11px;
	max-width: 275px;
}

.audience-container .config-container .audience-slider {
	margin-top: 8px;
	margin-left: 30px;
	max-width: 150px;
}

.audience-container .config-container .audience-slider .slider-size {
	width: auto;
	margin: 5px 0 0 0;
}

.audience-container .config-container .audience-slider.answers .slider-size {
	width: 150px;
}

.audience-container .config-container .form-item {
	margin-bottom: 2em;
	text-align: left;
}

.audience-container .config-container .form-item .label-config-form {
	margin-bottom: 1em;
	font-size: 12px;
	font-weight: 700;
	color: black;
	text-align: left;
}

.audience-container .config-container .form-item .radio-container {
	display: flex;
}

.audience-container .config-container .form-item .radio-container > div {
	margin-right: 15px;
}

.icon-audience {
	margin-top: 75px;
}

.item-arr {
	padding: 10px;
	border: solid 1px #4e54c8;
	margin: 0 10px;
}

.congrats-container .button-create {
	background-color: rgba(255, 205, 130, 0.8);
}

.congrats-container .button-create:hover {
	background-color: #ffcd82;
}

.modal-confirmation-container.single-button {
	width: 250px !important;
}

.modal-confirmation-container div {
	width: fit-content;
	margin: auto;
}

.modal-confirmation-container button {
	width: 100%;
	background-color: #5829bb !important;
	color: white !important;
}

.modal-login-container {
	width: 267px !important;
}

.modal-login-container div {
	width: auto;
	margin: auto;
}

.modal-login-container .modal-login-button {
	width: 100%;
	background-color: #5829bb;
	color: white;
}

.google_login_icon {
	height: 18px;
	width: 18px;
	margin-right: 12px;
}

.google-login-container {
	background-color: #fff;
	border: 2px solid #4285f4;
	color: #4285f4;
	display: flex;
	padding: 0;
	font-size: 18px;
	font-weight: 900;
	height: 44px;
	width: 100%;
	max-width: 100%;
	user-select: none;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	align-items: center;
	position: relative;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
}

.google-login-container:hover {
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.billing-container {
	height: 470px;
}

.billing-container .flex div:not(:last-child) {
	margin-right: 10px;
}

.plan > div {
	max-width: 685px;
}

.plan .description {
	font-size: smaller;
}

.plan .name {
	margin-right: 40px;
}

.plan .status-container {
	max-width: 245px;
	background-color: #4e54c8;
	border-radius: 5px;
	padding: 15px 35px;
}

.plan .status-container.pro {
	position: relative;
	background-color: white;
	border: solid 1px #4e54c8;
}

.plan .status-container.pro .header {
	margin: 6px 0;
}

.plan .status-container.pro svg {
	position: absolute;
	top: -25px;
	left: 35%;
}

.plan .status-container .upgrade-container {
	display: inline-block;
	position: relative;
}

.plan .status-container .upgrade-container svg {
	position: absolute;
	right: -29px;
	top: -4px;
}

.plan .status-container .upgrade-container .bg-white {
	opacity: 0.8;
}

.plan .status-container .upgrade-container .bg-white:hover {
	opacity: 1;
}

.plan table {
	border: 1px solid #4e54c8 !important;
}

.plan table tbody {
	font-weight: 500;
}

.plan table tfoot th {
	border-top: 1px solid #4e54c8 !important;
	cursor: pointer !important;
}

.plan table tfoot th:hover {
	background-color: #f0edfb !important;
	font-weight: 500;
}

.plan table th {
	background-color: #faf9fe !important;
}

.plan table td {
	border-top: 1px solid #4e54c8 !important;
	border-left: 1px solid #4e54c8 !important;
	padding: 0 15px 10px 15px !important;
	background-color: #faf9fe;
}

.plan table td:first-child {
	border-left: none !important;
}

.plan table td.last {
	padding: 10px 15px !important;
}

.plan table td:not(.last) {
	border-top: none !important;
}

.plan table td.selected {
	background-color: #f0edfb;
}

.plan table .title-row {
	font-weight: 700;
	font-size: large;
}

.plan table .small {
	font-size: small;
	text-align: center;
}

.payment .status-container {
	max-width: 245px;
	border-radius: 5px;
	padding: 15px 35px;
	position: relative;
	background-color: white;
	border: solid 1px #4e54c8;
}

.payment .status-container .header {
	margin: 6px 0;
}

.payment .status-container svg {
	position: absolute;
	top: -25px;
	left: 40%;
}

.payment > div {
	border-radius: 5px;
	-webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.payment > div .container {
	background-color: rgba(78, 84, 200, 0.05);
	border-radius: 5px;
	padding: 15px 30px;
	padding-bottom: 75px;
}

.payment > div .container .plans-container .plan-button:not(:last-child) {
	margin-bottom: 6px;
}

.payment > div .container .plans-container .plan-button {
	cursor: pointer;
	padding: 5px 10px;
	min-width: 445px;
	justify-content: space-between;
	color: black;
	border-radius: 5px;
	background-color: rgba(78, 84, 200, 0.1);
}

.payment > div .container .plans-container .plan-button.active {
	background-color: white;
	border: 1px solid #4e54c8;
}

.payment > div .container .plans-container .plan-button:hover {
	background-color: white;
	border: 1px solid #4e54c8;
}

.payment > div .container .plans-container .plan-button .description {
	line-height: 2;
}

.payment > div .container .plans-container .plan-button i {
	line-height: 2.15;
}

.payment > div .container .plans-container .plan-button .flex > *,
.payment > div .container .plans-container .plan-button .economy > * {
	margin-right: 5px;
}

.payment > div .container .plans-container .plan-button .economy {
	background-color: #4e54c8;
	border-radius: 5px;
	color: white;
	padding: 5px;
	font-size: x-small;
	line-height: 1.7;
}

.payment > div .container .description-container {
	background-color: white;
	border: 1px solid #4e54c8;
	padding: 5px 10px;
	text-align: left;
	margin-left: 30px;
}

.payment > div .container .description-container .title {
	font-size: medium;
}

.payment > div .container .description-container .subtitle {
	font-size: 10px;
	font-weight: 400;
	color: black;
}

.payment > div .container .description-container .separator {
	height: 1px;
	background-color: #4e54c8;
	width: 90%;
	margin: 10px auto;
}

.payment > div .footer {
	background-color: #26262c0d;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 15px 30px;
	color: black;
	font-size: smaller;
}

.payment > div .footer .separator-container {
	width: 10%;
}

.payment > div .footer .separator-container .separator {
	background-color: #4e54c8;
	height: 30px;
	width: 1px;
}

.payment > div .footer .methods > div:first-child {
	margin-bottom: 10px;
}

.payment > div .footer .methods svg:not(:last-child) {
	margin-right: 5px;
}

.payment > div .footer .icon-container {
	display: flex;
	align-items: center;
}

.payment > div .footer .ssl-description {
	margin-left: 15px;
	text-align: left;
}

.payment > div .footer .ssl-description div {
	margin-top: 10px;
}

.payment > div .footer .ssl-description .color-green {
	margin-right: 3px;
}

.payment > div .footer > div:not(.separator-container) {
	width: 45%;
}

.card-payment-container {
	margin-top: 10rem;
	max-width: 925px !important;
}

.card-payment-container .subtitle {
	font-size: 12px;
	font-weight: 500;
	color: #4e54c8;
}

.card-payment-container > div {
	width: 50%;
}

.card-payment-container > div:first-child {
	margin-right: 2em;
}

.card-payment-container .credit-card-container {
	min-width: 400px;
	min-height: 400px;
	position: relative;
}

.card-payment-container .credit-card-container .credit-card {
	position: absolute;
	background-color: #ffadad;
	padding: 15px;
	color: black;
	border-radius: 5px;
	max-width: 250px;
	box-shadow: 0 2px 8px #bbb;
	cursor: pointer;
}

.card-payment-container .credit-card-container .credit-card:hover,
.card-payment-container .credit-card-container .credit-card.selected {
	box-shadow: 0 20px 80px #bbb;
}

.card-payment-container .credit-card-container .credit-card:nth-child(1) {
	left: 10px;
	top: 40px;
	background: #ffadad;
}

.card-payment-container .credit-card-container .credit-card:nth-child(1):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(1).selected {
	left: 100px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(2) {
	left: 20px;
	top: 80px;
	background: #b7ffad;
}

.card-payment-container .credit-card-container .credit-card:nth-child(2):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(2).selected {
	left: 110px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(3) {
	left: 30px;
	top: 120px;
	background: #adfff8;
}

.card-payment-container .credit-card-container .credit-card:nth-child(3):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(3).selected {
	left: 120px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(4) {
	left: 40px;
	top: 160px;
	background: #adb2ff;
}

.card-payment-container .credit-card-container .credit-card:nth-child(4):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(4).selected {
	left: 130px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(5) {
	left: 50px;
	top: 200px;
	background: #e6adff;
}

.card-payment-container .credit-card-container .credit-card:nth-child(5):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(5).selected {
	left: 140px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .credit-card-container .credit-card:nth-child(6) {
	left: 60px;
	top: 240px;
	background: #ffadec;
}

.card-payment-container .credit-card-container .credit-card:nth-child(6):hover,
.card-payment-container
	.credit-card-container
	.credit-card:nth-child(6).selected {
	left: 150px;
}

.card-payment-container .credit-card-container .type {
	margin-left: 15px;
}

.card-payment-container .credit-card-container .flex {
	margin-top: 40px;
	text-align: right;
}

.card-payment-container .cardContainer {
	width: 370px;
}

.card-payment-container .sepaContainer {
	width: 370px;
	margin: auto;
}

.card-payment-container .price {
	background-color: white;
	padding: 0 30px;
	color: #4e54c8;
	border: dashed 1px #4e54c8;
	border-radius: 5px;
	display: inline-block;
	margin-bottom: 15px;
}

.va-middle {
	vertical-align: middle !important;
}

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
		rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
		rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.loading {
	height: 41.6px;
	opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
	padding: 0;
}

.error-payment {
	color: #ff6363 !important;
}

.starter-offers-container .card:first-child {
	margin-right: 15px;
}

.starter-offers-container .card:last-child {
	background-color: #4e54c813;
}

.starter-offers-container .card {
	border: solid 2px #4e54c8;
	border-radius: 10px;
	padding: 30px 40px;
}

.starter-offers-container .card > * {
	margin: 20px 0 0 0;
}

.starter-offers-container .card .ui.radio.checkbox label:before {
	width: 30px;
	height: 30px;
	top: -5px;
	left: -10px;
	border: 2px solid #4e54c8;
}

.starter-offers-container .card .ui.radio.checkbox label:after {
	top: -6px;
	left: -11px;
	width: 32px;
	height: 33px;
	background-color: #4e54c8;
}

.starter-offers-container .card .subtitle {
	font-size: 15px;
	font-weight: 400;
}

.starter-offers-container .card .subtitle.unavailable {
	opacity: 0.3;
}

.mandate-acceptance {
	margin-top: 10px;
	font-size: smaller;
}

.center-loader-subscription {
	width: 100%;
}

.create-stock-container {
	justify-content: initial;
}

.create-stock-container .top {
	width: 100%;
}

.create-stock-container .form-article-container {
	max-width: fit-content;
	background: rgba(78, 84, 200, 0.1);
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0 2px 8px #bbb;
	border: solid 1px #4e54c8;
	position: relative;
}

.create-stock-container .form-article-container .article-number {
	position: absolute;
	right: 50px;
}

.create-stock-container .left {
	flex-grow: 1;
}

.create-stock-container .right {
	flex-grow: 1;
}

@keyframes appear {
	0% {
	}
	width: 0;
	100% {
	}
	width: 100%;
}

.color-best-rating {
	color: #21ba45;
}

.color-better-rating {
	color: #b5cc18;
}

.color-lesser-rating {
	color: #fbbd08;
}

.color-worst-rating {
	color: #db2828;
}

.bgcolor-best-rating {
	background-color: #21ba45;
}

.bgcolor-better-rating {
	background-color: #b5cc18;
}

.bgcolor-lesser-rating {
	background-color: #fbbd08;
}

.bgcolor-worst-rating {
	background-color: #db2828;
}

.full-width {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
}

.global-rating-container {
	border-radius: 5px;
	margin-top: 15px;
	box-shadow: 0 0 10px 0 #e6e6e6;
}

.global-rating-container .global-cards-container {
	justify-content: center;
	display: flex;
}

.global-rating-container .global-cards-container.wrap {
	flex-wrap: wrap;
}

.global-rating-container .global-cards-container .centered {
	margin: 2em 5px;
}

.rating {
	border-radius: 5px;
	margin: 15px 0;
	min-height: 310px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	left: 50%;
	transform: translateX(-50%);
}

.rating.extremes {
	width: 250px;
	display: grid;
	grid-template-columns: 125px 125px;
}

.rating.extremes.single {
	grid-template-columns: 250px;
}

.rating.extremes.double {
	grid-template-columns: 125px 125px;
}

.rating.extremes .centered-grid {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.rating .middle-spacing {
	margin: 5px;
}

.best-rating {
	box-shadow: 0 0 10px 0 rgba(33, 186, 69, 0.3);
}

.worst-rating {
	box-shadow: 0 0 10px 0 rgba(219, 40, 40, 0.3);
}

.search-container {
	justify-content: space-evenly;
}

.stats-title {
	font-size: xx-large !important;
}

.stats-subtitle {
	font-size: large !important;
	display: inline-block;
	background: #4e54c8;
	color: white !important;
	padding: 10px !important;
	border-radius: 5px 5px 0 0;
}

.stats-subtitle.corner {
	border-bottom-right-radius: 5px;
}

.line-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.line-container .straight-container,
.line-container .large-container {
	margin: 15px;
	border-radius: 10px;
}

.line-container .straight-container {
	min-width: 200px;
	height: 100%;
}

.line-container .straight-container .stats-subtitle {
	margin: 0 !important;
}

.line-container .large-container {
	height: 100%;
	flex-grow: 1;
	min-width: 200px;
	box-shadow: 0 0 10px 0 #e6e6e6;
}

.lock-container .appearing-text {
	font-weight: bold;
	opacity: 0;
	visibility: hidden;
	transition: opacity 1s, visibility 1s;
}

.lock-container:hover .appearing-text {
	visibility: visible;
	opacity: 1;
}

.menu-container {
	display: flex !important;
	flex-direction: column;
	width: 100% !important;
	height: 100%;
}

.menu-container .notifications-container {
	align-self: center;
	flex-grow: 1;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	margin: 0 5px;
	max-width: fit-content;
	scrollbar-width: thin;
}

.menu-container .notifications-container > div {
	margin-bottom: 5px;
}

.menu-container .notifications-container > div:last-child {
	margin-bottom: 0;
}

.menu-container .notifications-container::-webkit-scrollbar {
	display: none;
}

.marker-container .multiple-marker-container {
	max-height: 600px;
}

.marker-container .multiple-marker-container .single-marker-container {
	border: solid 1px #4e54c8;
}

.marker-container .ct-point {
	z-index: 10;
}

.marker-container .ct-series-a .ct-point {
	stroke: green;
}

.marker-container .ct-series-b .ct-point {
	stroke: orange;
}

.marker-container .ct-series-c .ct-point {
	stroke: red;
}

.hoverable-list-item:hover {
	background: #54c8ff;
	cursor: pointer;
}
a {
  color: #252525;
}