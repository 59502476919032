.container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1px solid black;
}