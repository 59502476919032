.emptySquare {
	cursor: pointer;
	width: 50px;
	height: 50px;
	border: #00000057 2px solid;
	border-radius: 5px;
	text-align: center;
	font-size: xx-large;
	&:not(.checked):hover {
		background-color: #0000000d;
		border: black 2px solid;
	}
	&.checked {
		background-color: #0000000d;
		border: black 2px solid;
	}
}
