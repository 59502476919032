@import "../../styles/index.scss";

.topContainer{
    display: flex;
    width: 100vw;
    justify-content: space-around;
}

.updatedListTitle{
    cursor: pointer;
    &:hover{
        color: $primary-light;
    }
}