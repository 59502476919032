$white: #ffffff;
$text-light: #ffffff;
$black: #000000;
$text-dark: #000000;
$text-place-holder: #79869f;
$text-required: #f0bec5;

$info: #0085ff;
$info-light: #e6f3ff;

$success-dark: #1f9254;
$success: #00ba34;
$success-lighter: #44c568;
$success-light: #7bd995;

$primary-grad: linear-gradient(142.42deg, #ebbae6 0%, #c9af82 100%);
$primary: #252525;
$primary-light: #29475E;
$primary-lighter: #d5c4fa;
$primary-lightest: #f7f5ff;
$primary-grey: #ebbae666;
$primary-bg: rgba(88, 61, 114, 0.15);
$primary-dark: #c9af82;

$danger-dark: #a30d11;
$danger: #be5154;
$danger-light: #f4694c;
$danger-lighter: #f89e8b;
$danger-lightest: #fbe7e8;

$warning-dark: #cd6200;
$warning: #e5aa73;
$warning-light: #fef2e5;

$grey-1: #fafafa;
$grey-2: #e8e8e8;
$grey-3: #969696;
$grey-4: #464646;
$grey-5: #333333;

$badge-received-bg: #ffc1d0;
$box-shadow-light: 0px 0px 10px rgba(0, 0, 0, 0.1);
$box-shadow-dark: 0px 0px 10px rgba(0, 0, 0, 0.2);

$styleep-dark-blue: #0f3f62;
$purple-secondary: #c676be;
$secondary: #ebbae6;
$secondary-dark: #e398dc;
$secondary-light: #f6d0f2;
$tr-table: #F7F6FE;
$grey: #E0E0E0;
